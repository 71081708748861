import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { AuthorizationService, ErrorService, EventType, Play, RecurrenceType, ScheduleService, StoreSchedule } from '@ng-cloud/badger-core';
import { HttpClient } from '@angular/common/http';
import { ActionCableService } from 'angular2-actioncable';


@Injectable()
export class PlayService extends ApiService {
  constructor(
    protected http: HttpClient,
    protected cableService: ActionCableService,
    protected errorService: ErrorService,
    protected authService: AuthorizationService,
    private scheduleService: ScheduleService
  ) {
    super(http, cableService, errorService, authService);
  }

  getPlays(params?: any): Observable<Play[]> {
    return this.list(Play, '/plays', params);
  }

  getPlay(id: number, versionId?: number): Observable<Play> {
    const url = versionId ? `/plays/${id}/versions/${versionId}` : `/plays/${id}`;
    return this.get(Play, url, 300);
  }

  createPlay(play: Play): Observable<Play> {
    return this.create(play, '/plays');
  }

  updatePlay(play: Play): Observable<Play> {
    return this.update(play, `/plays/${play.id}`);
  }

  getPlayWithVersions(id: number): Observable<Play> {
    return this.get(Play, `/plays/${id}/versions`);
  }

  createNewVersion(play: Play): Observable<Play> {
    return this.create(play, `/plays/${play.id}/versions`);
  }

  /**
   * Fetch plays for given store, then mark each one as active or inactive.
   */
  getPlaysWithActiveStatus(storeId: number): Observable<Play[]> {
    return this.getPlays({ store_id: storeId }).pipe(
      switchMap((plays: Play[]) => this.scheduleService.getSchedules(storeId).pipe(
        switchMap((schedules: StoreSchedule[]) => {
          const activeSchedules = schedules.filter(s => s.status === 'active');

          // handle when there is no schedule for a store
          if (activeSchedules.length === 0) {
            this.markActivePlays(plays, []);
            return of(plays); // emit an observable
          }
          const observables = activeSchedules.map(sch =>
            this.scheduleService.getScheduleEvents(sch.id)
          );

          return forkJoin(observables).pipe(
            map(eventsArray => {
              const allEvents = _.flatten(eventsArray);
              this.markActivePlays(plays, allEvents);
              return plays;
            })
          );
        })
      ))
    );
  }

  private markActivePlays(plays: Play[], allEvents: any[]) {
    const oneWeekAgo = DateTime.now().minus({ weeks: 1 }).startOf('day');

    // For each event of type PLAY, set the corresponding play.active
    _.filter(allEvents, e => e.eventType === EventType.PLAY).forEach(event => {
      const eventPlay = _.find(plays, p => p.id === event.playId);
      if (!eventPlay) {
        return;
      } // skip if not found

      switch (event.recurrenceType) {
        case RecurrenceType.SINGLE_EVENT:
          // If the scheduled date is within the past week or in the future, mark active
          if (DateTime.fromJSDate(event.scheduledDate) > oneWeekAgo) {
            eventPlay.active = true;
          }
          break;

        case RecurrenceType.RECURRING_EVENT:
          // If lastRecurDate is null => infinite future recurrences => definitely active
          if (!event.lastRecurDate) {
            eventPlay.active = true;
          }
          else {
            if (DateTime.fromJSDate(event.lastRecurDate) > oneWeekAgo) {
              eventPlay.active = true;
            }
          }
          break;
      }
    });

    // Also consider plays updated in the past week as active
    for (const play of plays) {
      const updatedAt = DateTime.fromJSDate(play.updatedAt); // Convert to Luxon DateTime
      console.log(
        `Play: ${play.name} | Updated At: ${updatedAt.toISO()} | Recent: ${updatedAt > oneWeekAgo}`
      );

      if (updatedAt > oneWeekAgo) {
        play.active = true;
      }
    }

  }
}
