<div *ngIf="user" fxLayout="column" fxLayoutGap="20px" fxFlexFill class="user-info">
  <div fxLayout="column" class="user-details">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span class="header">Profile</span>
      <div class="theme-toggle">
        <button mat-icon-button
                (click)="toggleTheme()"
                [matTooltip]="currentTheme === 'badger-light' ? 'Dark Theme' : 'Light Theme'"
                [matTooltipShowDelay]="600">
          <mat-icon class="theme-toggle-icon">
            {{currentTheme === 'badger-light' ? 'dark_mode' : 'light_mode'}}
          </mat-icon>
        </button>
      </div>
    </div>

    <div #profile *ngIf="user" fxLayout="column" fxLayoutGap="20px">
      @if (user.firstName && user.lastName) {
      <div #firstName fxLayout="column" fxLayoutGap="5px">
        <span class="label">name</span>
        <span class="info">{{ user.firstName + " " + user.lastName}}</span>
        </div>
      }

      <div #email fxLayout="column" fxLayoutGap="5px">
        <span class="label">email</span>
        <span class="info">{{ user.email }}</span>
      </div>

      <div #organization fxLayout="column" fxLayoutGap="5px">
        <span class="label">organization</span>
        <span class="info">{{ user.organization?.name }}</span>
      </div>

      <div #store fxLayout="column" fxLayoutGap="5px">
        <span class="label">home store</span>
        <span class="info">{{ userHomeStore?.clientStoreId }}</span>
        <span class="info">{{ userHomeStore?.address }}</span>
        <span class="info">{{ userHomeStore?.city + ", " + userHomeStore?.state }}</span>
        <span class="info">{{ userHomeStore?.phone }}</span>
      </div>

      <div #roles fxLayout="column" fxLayoutGap="5px">
        <span class="label">{{ user.roles.length > 1 ? 'roles' : 'role' }}</span>
        <span class="info" *ngFor="let role of user.roles">{{ role }}</span>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="row" fxLayoutGap="10px" class="user-actions">
    <ng-container *ngIf="roleOptions?.length > 0">
      <button *hasAccess="['admin']" mat-stroked-button (click)="openEdit()">
        <mat-icon>edit</mat-icon>
        Edit
      </button>
    </ng-container>

    <button *ngIf="hasInsightAnalyticsAccess && ((portal == 'badger-portal') || (portal == 'weasel-portal'))" mat-stroked-button
            (click)="onConfigDashboard()">
      <mat-icon>settings</mat-icon>
      Configure Analytics
    </button>

    <button mat-stroked-button (click)="signOut()">
      <mat-icon>logout</mat-icon>
      Sign Out
    </button>
  </div>
</div>
