import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentListComponent } from './comment-list/comment-list.component';
import { CommentableListComponent } from './commentable-list/commentable-list.component';
import { CommentFormComponent } from './comment-form/comment-form.component';
import { BtCommonModule } from '../common/bt-common.module';
import { CommentsComponent } from './comments/comments.component';
import { CompactCommentsComponent } from '@ng-cloud/badger-core/comments/compact-comments/compact-comments.component';

@NgModule({
  imports: [
    CommonModule,
    BtCommonModule
  ],
  declarations: [
    CommentListComponent,
    CommentableListComponent,
    CommentFormComponent,
    CompactCommentsComponent,
    CommentsComponent
  ],
  exports: [
    CommentableListComponent,
    CommentFormComponent,
    CommentListComponent,
    CompactCommentsComponent,
    CommentsComponent
  ]
})
export class BtCommentsModule {
}
