import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {
  ClientSideDataTable,
  Column,
  Comment,
  Commentable,
  CommentFormComponent,
  CommentService,
  DialogService,
  UserService
} from '@ng-cloud/badger-core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'bt-comment-list',
    templateUrl: './comment-list.component.html',
    styleUrls: ['./comment-list.component.scss'],
    standalone: false
})
export class CommentListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataTable: ClientSideDataTable<Comment>;

  _commentable: Commentable;
  get commentable(): Commentable {
    return this._commentable;
  }

  @Input() set commentable(commentable: Commentable) {
    this._commentable = commentable;
    setTimeout(() => this.fetchComments(), 50);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommentListComponent>,
    public dialog: MatDialog,
    protected commentService: CommentService,
    protected dialogService: DialogService,
    protected userService: UserService) {
  }

  ngOnInit() {
    const columns = [
      new Column('date', { property: 'updatedAt' }),
      new Column('content'),
      new Column('edit')];

    this.dataTable = new ClientSideDataTable<Comment>(columns);
    this.dataTable.sort = this.sort;
  }

  fetchComments() {
    this.commentService.getComments(this.commentable).subscribe(comments => {
      this.dataTable.data = comments;
      comments.forEach(comment => {
        this.userService.getUser(comment.userId).subscribe(user => comment.user = user);
      });
    });
  }

  openCreate(): void {
    const dialogRef = this.dialog.open(CommentFormComponent, {
      data: {
        comment: new Comment(),
        commentable: this.commentable
      }
    });
    dialogRef.afterClosed().subscribe(comment => {
      if (comment) {
        this.userService.getUser(comment.userId).subscribe(user => comment.user = user);
        this.dataTable.data = this.dataTable.data.concat([comment]);
      }
    });
  }

  openEdit(comment): void {
    const dialogRef = this.dialog.open(CommentFormComponent, {
      data: {
        comment: comment,
        commentable: this.commentable
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      result && Object.assign(this.dataTable.data.find(r => result.id === r.id), result);
    });
  }

  deleteComment(comment: Comment): void {
    this.dialogService.confirm(`Are you sure you want to delete this comment?`).subscribe(confirmed => {
      if (confirmed) {
        this.commentService.deleteComment(comment).subscribe(() =>
          this.dataTable.data = this.dataTable.data.filter(deleted => deleted !== comment)
        );
      }
    });
  }

  closeDialog(data: any): void {
    this.dialogRef.close(data.data);
  }
}
