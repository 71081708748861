import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthorizationService } from './authorization.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private currentTheme: string;
  private defaultTheme = 'badger-light';
  private themeSource = new Subject<string>();

  constructor(private authService: AuthorizationService,
              private userService: UserService) {
    this.authService.getCurrentUser().subscribe(currentUser => {
      this.userService.getUser(currentUser.id).subscribe(user => {
        if (user.userPreferenceId) {
          this.userService.getUserPreference(user.id).subscribe(userPreference => {
            this.currentTheme = userPreference.preferences['theme'] ? userPreference.preferences['theme'] : this.defaultTheme;
            this.setCurrentTheme(this.currentTheme);
          });
        }
        else {
          this.currentTheme = this.defaultTheme;
          this.setCurrentTheme(this.currentTheme);
        }
      });
    });
  }

  themes(): Observable<string> {
    return this.themeSource.asObservable();
  }

  getCurrentTheme(): string {
    return this.currentTheme;
  }

  setDefaultTheme(defaultTheme: string) {
    if (!this.currentTheme) {
      this.setCurrentTheme(defaultTheme);
    }
  }

  setCurrentTheme(theme: string): void {
    this.currentTheme = theme;
    this.setStyle('theme', `${this.currentTheme}.css`);
    this.themeSource.next(this.currentTheme);
  }

  isDarkTheme(theme: string): boolean {
    return theme && theme.toLowerCase().includes('dark');
  }

  setStyle(key: string, href: string) {
    getLinkElementForKey(key).setAttribute('href', href);
  }
}

function getLinkElementForKey(key: string) {
  return getExistingLinkElementByKey(key) || createLinkElementWithKey(key);
}

function getExistingLinkElementByKey(key: string) {
  return document.head.querySelector(`link[rel="stylesheet"].${getClassNameForKey(key)}`);
}

function createLinkElementWithKey(key: string) {
  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.classList.add(getClassNameForKey(key));
  document.head.appendChild(linkEl);
  return linkEl;
}

function getClassNameForKey(key: string) {
  return `badger-style-${key}`;
}

