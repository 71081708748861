import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'solution',
    standalone: false
})
export class SolutionPipe implements PipeTransform {
  transform(value: string[], separator = ', '): string {
    const formattedSolutions = []
    value.forEach(solution => {
      switch (solution){
        case 'insight':
          formattedSolutions.push( _.replace(solution, 'insight', 'InSight'));
          break;
        case 'inspect':
          formattedSolutions.push(_.replace(solution, 'inspect', 'InSpect'));
          break;
        case 'uv_solution':
          formattedSolutions.push(_.replace(solution, 'uv_solution', 'UV Disinfect'));
          break;
        default:
          formattedSolutions.push(solution);
          break;
      }
    });
    return formattedSolutions.join(separator);
  }
}
