import { tap } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserFormComponent } from '../user-form/user-form.component';
import { Store } from '@ng-cloud/badger-core/models/store';
import { User } from '@ng-cloud/badger-core/models/user';
import { UserPreference } from '@ng-cloud/badger-core/models/user-preference';
import { UserService } from '@ng-cloud/badger-core/services/user.service';
import { OrganizationService } from '@ng-cloud/badger-core/services/organization.service';
import { AuthorizationService } from '@ng-cloud/badger-core/services/authorization.service';
import { StoreService } from '@ng-cloud/badger-core/services/store.service';
import { ThemeService } from '@ng-cloud/badger-core/services/theme.service';
import { TitleService } from '@ng-cloud/badger-core/services/title.service';
import * as _ from 'lodash';

@Component({
  selector: 'bt-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class UserDetailComponent implements OnInit {
  id: number;
  user: User;
  currentUserId: number;
  roleOptions: string[];
  hasInsightAnalyticsAccess = false;
  portal;
  currentTheme: 'badger-light' | 'badger-dark' = 'badger-light';
  userHomeStore: Store;
  userPreferences: UserPreference;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private userService: UserService,
    private organizationService: OrganizationService,
    private authService: AuthorizationService,
    private storeService: StoreService,
    private themeService: ThemeService,
    private titleService: TitleService,
    private router: Router
  ) {
    this.currentTheme = themeService.getCurrentTheme() as 'badger-light' | 'badger-dark';
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.authService.validateToken().subscribe(() => {
        this.authService.getCurrentUser().subscribe(currentUserData => {
          if (currentUserData.roles.includes('admin') || currentUserData.roles.includes('organization_admin')) {
            this.userService.getRoles().subscribe(roles => this.roleOptions = roles);
          }
          if (currentUserData.roles.includes('insight_analytics')) {
            this.hasInsightAnalyticsAccess = true;
          }
          this.currentUserId = currentUserData.id;
          this.id = +params.get('id') || this.currentUserId;

          this.userService.userPreferences(this.currentUserId).subscribe(response => {
            if (this.userPreferences == undefined) {
              this.userPreferences = new UserPreference();
              this.userPreferences.userId = response['user_id'];
              this.userPreferences.preferences = response['preferences'];
            }

            if (response.preferences && response.preferences['theme']) {
              this.userPreferences.preferences = response.preferences;
              this.currentTheme = response.preferences['theme'];
              this.themeService.setCurrentTheme(this.currentTheme);
            }
          });

          this.userService.getUser(this.id).pipe(
            tap(user => {
              this.titleService.setTitle(user.uid);
              if (user.organizationId) {
                this.organizationService.addOrganization(user).subscribe();
              }

              if (user.homeStoreId) {
                this.storeService.getStore(user.homeStoreId).subscribe(store => {
                  this.userHomeStore = store;
                });
              }
            }))
            .subscribe({ next: currentUser => {
              this.user = currentUser;
              if(this.user.userPreferenceId){
                this.userService.getUserPreference(this.user.id).subscribe(preference => {
                  this.userPreferences = preference;
                  if (this.userPreferences.preferences && this.userPreferences.preferences['theme']){
                    this.currentTheme = this.userPreferences.preferences['theme'];
                    this.themeService.setCurrentTheme(this.currentTheme);
                  }
                });
              }
            },
              error: () => this.router.navigate(['/'])
            });
        });
      });
    });

    this.route.data.subscribe(data => {
      if (data && data.portal) {
        this.portal = data.portal;
      }
    });
  }

  openEdit(): void {
    const dialogRef = this.dialog.open(UserFormComponent, {
      data: { user: this.user, roleOptions: this.roleOptions }
    });
    dialogRef.afterClosed().subscribe(result => this.user = result || this.user);
  }

  getRoles() {
    return this.user.roles.length === 0 ? 'N/A' : _.join(this.user.roles, ', ');
  }

  onConfigDashboard() {
    this.userService.triggerConfigureDashboard();
  }

  toggleTheme(): void {
    this.currentTheme = this.currentTheme === 'badger-light' ? 'badger-dark' : 'badger-light';
    if (this.userPreferences) {
      if ( this.userPreferences.preferences) {
        this.userPreferences.preferences['theme']= this.currentTheme;
      }
      else {
        this.userPreferences.preferences = {'theme': this.currentTheme};
      }
      this.userService.updateUserPreference(this.userPreferences).subscribe();
    }
    else {
      this.userPreferences = new UserPreference();
      this.userPreferences.userId = this.currentUserId;
      this.userPreferences.preferences ={'theme': this.currentTheme};
      this.userService.createUserPreference(this.userPreferences).subscribe();
    }
  }

  signOut(): void {
    this.authService.signOut();
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate(['/']);
  }
}

