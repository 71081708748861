import { MapGrouping } from './map-grouping';
import { Layers } from './map-layer';
import { ZoneObject } from '@ng-cloud/badger-core/map/objects/zone-object';
import { ScanZone } from '@ng-cloud/badger-core/models/scan-zone';
import { MapGroupEntry } from '@ng-cloud/badger-core';

import * as _ from 'lodash';

@Layers([
  { name: 'scanZones', zIndex: 25 }
])
export class ScanZoneGrouping extends MapGrouping<ScanZone, ZoneObject> {
  private readonly DEFAULT_FONT_SIZE = 14;

  colors = [
    'rgb(169,84,134)',
    'rgb(10,71,138)',
    'rgb(77,140,26)',
    'rgb(114,68,86)',
    'rgb(51,129,169)',
    'rgb(108,128,71)',
    'rgb(169,90,37)',
    'rgb(103,51,51)',
    'rgb(168,156,4)'
  ];

  createObject(data: ScanZone): ZoneObject {
    const points = data.points.map(pt => this.storeMap.pointToPixels(pt));

    return new ZoneObject(data, points, Object.assign(this.getColor(data.name), this.options));
  }

  setData(data: ScanZone[]): MapGroupEntry<ScanZone, ZoneObject>[] {
    return super.setData(data);
  }

  protected getFontSize(): number {
    return Math.max(Math.ceil(this.storeMap.width / 150), this.DEFAULT_FONT_SIZE);
  }

  protected getColor(zoneId: string) {
    let asciiSum = 0;

    // When summing ascii values treat consecutive digits as one number.  This makes names like
    // 'aisle_19' and 'aisle_20' have consecutive values and allows us to control what colors are
    // adjacent in the map somewhat.
    _.words(zoneId).forEach((word) => {
      if (/^\d+$/.test(word)) {
        asciiSum += _.toNumber(word);
      }
      else {
        asciiSum += _.sumBy(_.toArray(word), char => char.charCodeAt(0));
      }
    });

    const colorIndex = asciiSum % this.colors.length;

    return {
      polygonOptions: { fill: this.colors[colorIndex] },
      labelOptions: { textBackgroundColor: this.colors[colorIndex], fontSize: this.getFontSize() }
    };
  }
}
