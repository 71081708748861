import { AbstractApiModel } from './abstract-api-model';

export class UserPreference extends AbstractApiModel<UserPreference> {
  preferences: any;
  userId: number;

  deserialize(json: any): this {
    this.preferences = json.preferences;
    this.userId = json.user_id;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      user_id: this.userId,
      preferences: this.preferences
    });
  }
}
