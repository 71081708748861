import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Map, ScanEventIssue } from '@ng-cloud/badger-core';


@Component({
    selector: 'bt-issue-map-dialog',
    templateUrl: './issue-map-dialog.component.html',
    styleUrls: ['./issue-map-dialog.component.scss'],
    standalone: false
})
export class IssueMapDialogComponent {
  title: string;
  size: string;
  storeMap: Map;
  scanEventIssue: ScanEventIssue;

  constructor(
    public dialogRef: MatDialogRef<IssueMapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.storeMap = data.storeMap;
    this.scanEventIssue = data.scanEventIssue;
    this.size = data.size;
  }
}








