import { AbstractApiModel } from './abstract-api-model';
import { HasOrganization, Organization, HasMultiStore, Store } from '@ng-cloud/badger-core';

export class User extends AbstractApiModel<User> implements HasOrganization, HasMultiStore {
  email: string;
  firstName: string;
  lastName: string;
  signInCount: number;
  currentSignInAt: Date;
  lastSignInAt: Date;
  currentSignInIp: string;
  lastSignInIp: string;
  uid: string;
  roles: string[];
  organizationId: number;
  rolesString: string;
  homeStoreId: number;
  organization: Organization;
  storeIds: number[];
  stores: Store[];
  maxRoleRank: number;

  userPreferenceId: number;
  store: Store;
  unrestricted = false;

  deserialize(json: any): this {
    this.email = json.email;
    this.firstName = json.first_name;
    this.lastName = json.last_name;
    this.signInCount = json.sign_in_count;
    this.currentSignInAt = json.current_sign_in_at;
    this.lastSignInAt = json.last_sign_in_at;
    this.currentSignInIp = json.current_sign_in_ip;
    this.lastSignInIp = json.last_sign_in_ip;
    this.uid = json.uid;
    this.roles = json.roles;
    this.rolesString = json.roles.toString();
    this.organizationId = json.organization_id;
    this.homeStoreId = json.home_store_id;
    this.userPreferenceId = json.user_preference_id;
    this.storeIds = json.allowed_store_ids;
    this.maxRoleRank = json.max_role_rank;

    return super.deserialize(json);
  }

  serialize(): any {
    const finalStoreIds = this.unrestricted ? undefined : this.storeIds;


    return Object.assign(super.serialize(), {
      email: this.email,
      first_name: this.firstName,
      last_name: this.lastName,
      sign_in_count: this.signInCount,
      current_sign_in_at: this.currentSignInAt,
      last_sign_in_at: this.lastSignInAt,
      current_sign_in_ip: this.currentSignInIp,
      last_sign_in_ip: this.lastSignInIp,
      uid: this.uid,
      roles: this.roles,
      organization_id: this.organizationId,
      home_store_id: this.homeStoreId,
      allowed_stores: finalStoreIds
    });
  }
}
