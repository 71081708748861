import { AbstractApiModel } from './abstract-api-model';
import { Comment, Commentable, Point3d, ScanEventTag, Viewport, ViewportImage } from '@ng-cloud/badger-core';

export enum TaskType {
  PRICE = 'price',
  SALE_PRICE = 'sale_price',
  HOLE = 'hole',
  WRONG = 'wrong'
}

export class Task extends AbstractApiModel<Task> implements Commentable {
  taskType: TaskType;
  status: string;
  description: string;
  duplicateCount: number;
  priority: number;
  actionable: boolean;
  metadata: any;

  storeLocation: Point3d[];
  shelfLocation: number[];

  playId: number;
  playName: string;
  productId: number;
  skuId: number;
  storeId: number;
  departmentId: number;

  suppressUntil: Date;

  assignedAt: Date;
  assignedTo: number;

  resolvedAt: Date;
  resolvedBy: number;
  taskResolutionId: number;

  resolution: any;
  resolutionType: any;

  whereaboutType: string;
  whereaboutId: number;
  whereaboutName: string;

  comments: Comment[] = [];

  viewport: Viewport;
  productImageUrl: string;

  deserialize(json: any): this {
    this.taskType = json.task_type;

    this.status = json.status;
    this.description = json.description;
    this.duplicateCount = json.duplicate_count;
    this.priority = json.priority;
    this.actionable = json.actionable;
    this.metadata = json.metadata;
    this.storeLocation = json.store_polygon;
    this.storeId = json.store_id;
    this.playId = json.play_id;
    this.playName = json.play_name;
    this.productId = json.product_id;
    this.skuId = json.sku_id;
    this.departmentId = json.department_id;
    this.suppressUntil = json.suppress_until && new Date(json.suppress_until);
    this.assignedAt = json.assigned_at && new Date(json.assigned_at);
    this.assignedTo = json.assigned_to;
    this.resolvedAt = json.resolved_at && new Date(json.resolved_at);
    this.resolvedBy = json.resolved_by;
    this.taskResolutionId = json.task_resolution_id;
    this.resolution = json.resolution;
    this.resolutionType = json.resolution_type;
    this.whereaboutType = json.whereabout_type;
    this.whereaboutId = json.whereabout_id;
    this.whereaboutName = json.whereabout_name;

    this.viewport = json.viewport && new Viewport().deserialize(json.viewport);

    if (this.viewport && json.image_urls) {
      json.image_urls.forEach((imageUrl) => {
        this.viewport.viewportImages.push(new ViewportImage().deserialize(imageUrl));
      });
    }

    if (this.viewport && json.tag) {
      this.viewport.tag = new ScanEventTag().deserialize(json.tag);
    }

    this.productImageUrl = json.product_image_url;
    this.shelfLocation = json.metadata.shelf_polygon;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      task_resolution_id: this.taskResolutionId
    });
  }

  getCommentableType(): string {
    return 'Task';
  }

  getDisplayName(): string {
    // TODO: using "product_name" will not work once we have new task types.
    return this.metadata.product_name;
  }

  getTaskTypeDisplayName(): string {
    let taskTypeDisplayName = 'unknown';

    switch (this.taskType) {
      case TaskType.PRICE:
        taskTypeDisplayName = 'Price';
        break;
      case TaskType.SALE_PRICE:
        taskTypeDisplayName = 'Sale Price';
        break;
      case TaskType.HOLE:
        taskTypeDisplayName = 'Out-of-Stock';
        break;
      case TaskType.WRONG:
        taskTypeDisplayName = 'Incorrect Product';
        break;
    }

    return taskTypeDisplayName;
  }
}
