import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatSlider } from '@angular/material/slider';

import { InsightImage } from '../models/interfaces/insight-image';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import * as _ from 'lodash';

@Component({
    selector: 'bt-insight-image',
    templateUrl: './insight-image.component.html',
    styleUrls: ['./insight-image.component.scss'],
    standalone: false
})
export class InsightImageComponent implements OnInit {
  public static BRIGHTNESS_MAX = 450;
  public static BRIGHTNESS_MIN = 50;
  public static BRIGHTNESS_DEFAULT = 130;
  public static HIGH_CONTRAST = 110;
  public static LOW_CONTRAST = 90;

  public readonly brightnessMax = InsightImageComponent.BRIGHTNESS_MAX;
  public readonly brightnessMin = InsightImageComponent.BRIGHTNESS_MIN;

  @ViewChild('insightImageContainer', { static: true }) insightImageContainer: ElementRef;
  @ViewChild('insightImage', { static: true }) insightImage: ElementRef;
  @ViewChild('brightnessSlider') brightnessSlider: MatSlider;

  @Input() set brightnessControlVisible(value: boolean) {
    this._brightnessControlVisible = value;
  }

  get brightnessControlVisible(): boolean {
    return this._brightnessControlVisible;
  }

  @Input() set clickToZoom(value: boolean) {
    this._clickToZoom = value;
    if (value === false) {
      this.insightImageContainer.nativeElement.style.cursor = 'pointer';
    }
  }

  get clickToZoom(): boolean {
    return this._clickToZoom;
  }

  @Input() set imageSrc(value: string) {
    this.setImageUrl(value);
  }

  @Input() set insightImageSrc(value: InsightImage) {
    value && this.setImageUrl(value.getImageUrl(), value);
  }

  @Output() imageComplete: EventEmitter<number> = new EventEmitter<number>();

  insightObjectOptions: any = {
    surroundingOpacity: 0.5,
    opacity: 0.7,
    strokeWidth: 10,
    stroke: 'greenyellow'
  };

  public imageUrl: string;
  public imageStatus: string;
  public insightObjectsImageUrl: string;
  public filter: SafeStyle;
  public scaleFactor: number;
  public hideNavControls = false;

  protected lastValidUrl = '';
  protected _clickToZoom = true;
  protected _brightnessControlVisible = false;

  brightness = InsightImageComponent.BRIGHTNESS_DEFAULT;
  contrast = InsightImageComponent.HIGH_CONTRAST;

  transformOrigin: string;

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.scaleFactor = 1;
    this.transformOrigin = '100% 100%';
    this.setFilter();
  }

  setInsightImage(insightImage: InsightImage) {
    if (insightImage && (insightImage.getImageStatus() === 'realized')) {
      this.imageStatus = insightImage.getImageStatus();
      this.setImageUrl(insightImage.getImageUrl(), insightImage);
    }
    else {
      this.setImageUrl(null);
    }
  }

  setImageUrl(url: string, insightImage?: InsightImage) {
    this.setZoomMode(false);

    if (url) {
      const image = new Image();
      this.lastValidUrl = url;

      image.onload = () => {
        if (url === this.lastValidUrl) {
          this.imageUrl = url;
          if (insightImage) {
            const tempCanvas = document.createElement('canvas');
            tempCanvas.width = image.width;
            tempCanvas.height = image.height;

            this.imageComplete.emit(image.width / image.height);

            const tempContext = tempCanvas.getContext('2d');

            if (insightImage.getObjectRects().length > 0) {
              tempContext.globalAlpha = this.insightObjectOptions.surroundingOpacity;
              tempContext.fillRect(0, 0, image.width, image.height);
            }

            _.forEach(insightImage.getObjectRects(), objectRect => {
              tempContext.globalAlpha = this.insightObjectOptions.opacity;
              tempContext.strokeStyle = insightImage.getObjectColor();
              tempContext.lineWidth = this.insightObjectOptions.strokeWidth;
              tempContext.beginPath();

              objectRect.forEach((point, index) => {
                if (index === 0) {
                  tempContext.moveTo(point.x, point.y);
                }
                else {
                  tempContext.lineTo(point.x, point.y);
                }
              });

              tempContext.closePath();
              tempContext.save();
              tempContext.clip();
              tempContext.clearRect(0, 0, image.width, image.height);
              tempContext.stroke();
              tempContext.restore();
            });

            this.insightObjectsImageUrl = tempCanvas.toDataURL();
          }
          else {
            this.insightObjectsImageUrl = null;
          }
        }
      };

      image.onerror = () => {
        this.imageUrl = null;
      };

      image.src = url;
    }
    else {
      this.imageUrl = null;
      this.imageStatus = null;
      this.insightObjectsImageUrl = null;
    }
  }

  setZoomMode(zoom: boolean) {
    if (this.clickToZoom === true) {
      this.scaleFactor = zoom === true ? 3 : 1;
      this.hideNavControls = zoom;

      zoom === true ? this.insightImageContainer.nativeElement.style.cursor = 'zoom-out' : this.insightImageContainer.nativeElement.style.cursor = 'zoom-in';
    }
  }

  setFilter() {
    this.filter = this.sanitizer.bypassSecurityTrustStyle('contrast(' + this.contrast + '%) brightness(' + this.brightness + '%)');
  }

  setBrightness(value: number) {
    this.brightness = value;

    if (this.brightness > 300) {
      this.contrast = InsightImageComponent.LOW_CONTRAST;
    }
    else {
      this.contrast = InsightImageComponent.HIGH_CONTRAST;
    }
    this.setFilter();
  }

  mouseDown() {
    if (this.clickToZoom === true) {
      this.scaleFactor === 1 ? this.setZoomMode(true) : this.setZoomMode(false);
    }
  }

  mouseMove(event: MouseEvent) {
    if (this.clickToZoom) {
      const x = (event.offsetX / this.insightImageContainer.nativeElement.clientWidth) * 100;
      const y = (event.offsetY / this.insightImageContainer.nativeElement.clientHeight) * 100;

      this.transformOrigin = '' + x + '% ' + y + '%';
    }
  }
}
