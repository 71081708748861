import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { CsvReportsService } from '../analytics-common/services/csv-report.service';

@Component({
    selector: 'insight-csv-report-toast-component',
    imports: [CommonModule],
    styles: [`
    :host {
      background-color: #FF69B4;
      position: relative;
      overflow: hidden;
      margin: 0 0 6px;
      padding: 10px 10px 10px 10px;
      width: 350px;
      border-radius: 3px 3px 3px 3px;
      color: #FFFFFF;
      pointer-events: auto;
      cursor: pointer;
    }`],
    template: `
    <div style="padding-left: 50px">
      <div style="font-size: 14px">{{ title }}</div>
      <div style="font-size:12px">
        {{ message }}
        <a *ngIf="!options.payload.failed" (click)="action($event,options.payload.id)" title="Download" style="color:white;">
          <span class="material-icons">save_alt</span>
        </a>
      </div>
    </div>`
})
export class CsvReportToastComponent extends Toast {

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private csvReportServce: CsvReportsService
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event, id: number) {
    event.stopPropagation();

    this.csvReportServce.downloadReport(id);
    this.toastPackage.triggerAction();
    return false;
  }
}
