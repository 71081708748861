import { Component, Input, OnInit } from '@angular/core';
import { MapComponent } from '@ng-cloud/badger-core/map/map.component';
import { StoreZoneGrouping } from '@ng-cloud/badger-core/map/groupings/store-zone-grouping';
import { StoreService } from '@ng-cloud/badger-core/services/store.service';
import { Map } from '@ng-cloud/badger-core/models/map';
import { IdlePoseGrouping } from '@ng-cloud/badger-core/map/groupings/idle-pose-grouping';

@Component({
    selector: 'bt-store-map',
    templateUrl: './store-map.component.html',
    styleUrls: ['./store-map.component.scss'],
    standalone: false
})
export class StoreMapComponent implements OnInit {
  @Input() storeId: number;
  map: Map;
  mapComponent: MapComponent;

  canvasOptions: any = {
    defaultCursor: 'pointer',
    allowTouchScrolling: true
  };
  zoneGrouping: StoreZoneGrouping = new StoreZoneGrouping();
  idlePoseGrouping: IdlePoseGrouping = new IdlePoseGrouping();

  constructor(
    protected storeService: StoreService
  ) {
  }

  ngOnInit() {
    this.storeService.getMap(this.storeId).subscribe(map => this.map = map);
    this.storeService.getZones(this.storeId).subscribe(zones => this.zoneGrouping.setData(zones));
    this.storeService.getIdlePoses(this.storeId).subscribe(idlePoses => this.idlePoseGrouping.setData(idlePoses));
  }

  onMapLoad(mapComponent: MapComponent) {
    this.mapComponent = mapComponent;
    this.mapComponent.addGrouping(this.zoneGrouping);
    this.mapComponent.addGrouping(this.idlePoseGrouping);
    this.mapComponent.render();
  }
}
