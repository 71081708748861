import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'bt-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AlertDialogComponent {
  text: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.text = data.text;
  }
}
