import { Component, Input, OnInit } from '@angular/core';
import { Map } from '../../models/map';
import { ScanEventIssue } from '../../models/scan-event-issue';
import { Point } from '../../models/interfaces/point';
import { ScanEventIssueGrouping } from '../../map/groupings/scan-event-issue-grouping';
import { ScanEventIssueObject } from '../../map/objects/scan-event-issue-object';

@Component({
    selector: 'bt-issue-map-thumb',
    templateUrl: './issue-map-thumb.component.html',
    styleUrls: ['./issue-map-thumb.component.scss'],
    standalone: false
})
export class IssueMapThumbComponent implements OnInit {

  @Input() scanEventIssue: ScanEventIssue;
  @Input() storeMap: Map;

  public overlayImageUrl;

  constructor() {
  }

  ngOnInit() {
    this.createImageOverlayUrl();
  }

  createImageOverlayUrl() {
    const image = new Image();

    image.onload = () => {
      if (this.scanEventIssue) {
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = image.width;
        tempCanvas.height = image.height;

        const points = ScanEventIssueGrouping.getPoints(this.scanEventIssue, this.storeMap);
        const colors = ScanEventIssueGrouping.getColor(this.scanEventIssue);

        const centerPoint: Point = ScanEventIssueObject.getCenterPoint(points);

        let thumbScale: number;

        if (this.storeMap.width > this.storeMap.height) {
          thumbScale = 200 / this.storeMap.width;
        }
        else {
          thumbScale = 200 / this.storeMap.height;
        }

        centerPoint.x = centerPoint.x * thumbScale;
        centerPoint.y = centerPoint.y * thumbScale;

        const tempContext = tempCanvas.getContext('2d');
        tempContext.globalAlpha = 0.3;
        tempContext.beginPath();
        tempContext.arc(centerPoint.x, centerPoint.y, 6, 0, Math.PI * 2, true);
        tempContext.closePath();
        tempContext.fillStyle = colors.stroke;
        tempContext.fill();

        tempContext.lineWidth = 3;
        tempContext.globalAlpha = 1.0;
        tempContext.beginPath();
        tempContext.arc(centerPoint.x, centerPoint.y, 8, 0, Math.PI * 2, true);
        tempContext.closePath();
        tempContext.strokeStyle = colors.stroke;
        tempContext.stroke();

        this.overlayImageUrl = tempCanvas.toDataURL();
      }
      else {
        this.overlayImageUrl = null;
      }
    };

    image.src = this.storeMap.image['thumb'];
  }
}
