import { Component, Input, OnInit } from '@angular/core';
import { StoreService } from '../../services/store.service';
import { Play } from '../../models/play';
import { Map } from '../../models/map';

import * as _ from 'lodash';

@Component({
    selector: 'bt-play-thumb',
    templateUrl: './play-thumb.component.html',
    styleUrls: ['./play-thumb.component.scss'],
    standalone: false
})
export class PlayThumbComponent implements OnInit {
  @Input() play: Play;
  @Input() showActions = true;
  @Input() showActive = false;

  storeMap: Map;
  backgroundColor = '#303030';

  constructor(
    protected storeService: StoreService
  ) {
  }

  ngOnInit() {
    this.backgroundColor = this.getPlayColor(this.play);
    this.storeService.getMap(this.play.storeId).subscribe(map => {
      this.storeMap = map;
    });
  }

  getPlayTypeName() {
    return Play.getPlayTypeName(this.play.playType);
  }

  getPlayColor(play: Play) {
    let color = '#303030';

    if (!_.isNil(play)) {
      switch (play.playType) {
        case Play.TYPE_INSIGHT:
          color = '#607D8B';
          break;
        case Play.TYPE_INSPECT:
          color = '#673AB7';
          break;
        case Play.TYPE_FOCUS:
          color = '#32965f';
          break;
        case Play.TYPE_RFID:
          color = '#853a5a';
          break;
      }
    }

    return color;
  }
}
