import { MapGrouping } from './map-grouping';
import { Map } from '../../models/map';
import { Layers, MapLayer } from './map-layer';
import { Task, TaskType } from '@ng-cloud/badger-core/models/task';
import { TaskObject } from '@ng-cloud/badger-core/map/objects/task-object';

@Layers([
  { name: 'tasks', zIndex: 1 }
])

export class TasksGrouping extends MapGrouping<Task, TaskObject> {

  public static typeColors = {
    [TaskType.PRICE]: '#A3DF02',
    [TaskType.SALE_PRICE]: '#FFCB24',
    [TaskType.HOLE]: '#FF5722',
    [TaskType.WRONG]: '#FF5722'
  };

  static getColor(task: Task) {
    return {
      fill: this.getColorForTaskType(task.taskType, false)
    };
  }

  static getColorForTaskType(taskType: string, withAlpha: boolean = false): string {
    const color = TasksGrouping.typeColors[taskType].substring(1);
    const red = parseInt(color.substring(0, 2), 16);
    const green = parseInt(color.substring(2, 4), 16);
    const blue = parseInt(color.substring(4, 6), 16);
    const alpha = withAlpha ? '0.2' : '1.0';

    return 'rgba(' + red + ',' + green + ',' + blue + ',' + alpha + ')';
  }

  static getPoints(task: Task, storeMap: Map) {
    return task.storeLocation.map(pt => storeMap.pointToPixels(pt));
  }

  constructor(public options: any = {}, public layerName: string = 'Tasks', public zIndex: number = 1) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  createObject(task: Task): TaskObject {
    const points = TasksGrouping.getPoints(task, this.storeMap);
    return new TaskObject(task, points, Object.assign(TasksGrouping.getColor(task), this.options));
  }
}

