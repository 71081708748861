import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { CdnFile } from '@ng-cloud/badger-core';

@Component({
    selector: 'bt-image-viewer-dialog',
    templateUrl: './image-viewer-dialog.component.html',
    styleUrls: ['./image-viewer-dialog.component.scss'],
    standalone: false
})

export class ImageViewerDialogComponent {
  title: string;
  images: CdnFile[];
  selectedImageIndex: number;

  constructor(
    public dialogRef: MatDialogRef<ImageViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.images = data.images;
    this.selectedImageIndex = data.selectedIndex;
  }
}
