import { AbstractApiModel } from './abstract-api-model';

export class CustomReport extends AbstractApiModel<CustomReport> {
  mimeType: string;
  name: string;
  reportType: string;
  reportFileId: number;
  reportOwnerOrgId: number;
  storeId: number;
  startTime: Date;
  endTime: Date;
  playExecutionId: number;
  url: string;
  metadata: any;

  deserialize(json: any): this {
    this.mimeType = json.mime_type;
    this.name = json.name;
    this.reportType = json.report_type;
    this.reportFileId = json.report_file_id;
    this.reportOwnerOrgId = json.report_owner_organization_id;
    this.storeId = json.store_id;
    this.startTime = new Date(json.start_time);
    this.endTime = new Date(json.end_time);
    this.playExecutionId = json.play_execution_id;
    this.url = json.url;
    this.metadata = json.metadata;

    return super.deserialize(json);
  }
}
