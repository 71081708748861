import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Map } from '../../models/map';
import { MapComponent } from '../../map/map.component';
import { ScanEventIssueGrouping } from '../../map/groupings/scan-event-issue-grouping';
import { ScanEventIssue } from '../../models/scan-event-issue';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'bt-issue-map',
  templateUrl: './issue-map.component.html',
  styleUrls: ['./issue-map.component.scss'],
  standalone: false
})
export class IssueMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() scanEventIssue: ScanEventIssue;
  @Input() storeMap: Map;
  @Input() size: string;
  @Input() zoom: string | number;

  protected mapComponent: MapComponent;
  protected scanEventIssueGrouping: ScanEventIssueGrouping = new ScanEventIssueGrouping();
  protected resizeObservable: ResizeObserver;
  protected clientWidth = 0;
  protected clientHeight = 0;

  constructor(
    private elementRef: ElementRef,
    protected storeService: StoreService
  ) {
  }

  ngOnInit() {
    this.scanEventIssueGrouping.storeMap = this.storeMap;
    this.scanEventIssueGrouping.addData(this.scanEventIssue);
  }

  ngAfterViewInit() {
    this.resizeObservable = new ResizeObserver((entries) => {
      const entry = entries[0];
      this.clientWidth = entry.contentRect.width;
      this.clientHeight = entry.contentRect.height;
    });

    this.resizeObservable.observe(this.elementRef.nativeElement.parentElement);
  }

  ngOnDestroy() {
    this.resizeObservable.disconnect();
  }

  onMapLoad(mapComponent: MapComponent) {
    this.mapComponent = mapComponent;
    this.mapComponent.addGrouping(this.scanEventIssueGrouping);
    this.mapComponent.clientWidth = this.clientWidth;
    this.mapComponent.clientHeight = this.clientHeight;
    this.mapComponent.render();
    this.mapComponent.fillBoundingArea();
    this.panAndZoomToIssue();
  }

  public panAndZoomToIssue() {
    const points = ScanEventIssueGrouping.getPoints(this.scanEventIssue, this.storeMap);
    this.mapComponent.panAndZoomTo({ x: points[0].x, y: points[0].y });
  }
}
