import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@ng-cloud/badger-core/models/user';
import { AppForm } from '@ng-cloud/badger-core/common/app-form';
import { UserService } from '@ng-cloud/badger-core/services/user.service';
import { OrganizationService } from '@ng-cloud/badger-core/services/organization.service';
import { Organization } from '@ng-cloud/badger-core/models/organization';
import { Store } from '@ng-cloud/badger-core/models/store';
import { StoreService } from '@ng-cloud/badger-core/services/store.service';
import { debounceTime } from 'rxjs/operators';
import { AuthorizationService } from '@ng-cloud/badger-core/services/authorization.service';

@Component({
    selector: 'bt-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class UserFormComponent {
  user: User;
  form: AppForm<User>;
  formGroup: UntypedFormGroup;
  roleOptions: string[] = [];
  orgs: Organization[] = [];
  stores: Store[] = [];
  storeSearchControl = new FormControl('');
  filteredStores: Store[] = [];
  assignableStores: Store[] = [];
  homeStoreSearchControl = new FormControl('');
  filteredHomeStores: Store[] = [];
  potentialStoreIds: string;
  singleStoreRestricted = false;
  storeSetLoaded = false;
  currentUser: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserFormComponent>,
    private storeService: StoreService,
    private userService: UserService,
    private authService: AuthorizationService,
    private organizationService: OrganizationService,
  ) {
    // this.userService.getRoles().subscribe(roles => this.roleOptions = roles);
    this.roleOptions = data.roleOptions;
    this.organizationService.getOrganizations().subscribe(organizations => this.orgs = organizations);
    this.storeService.getStores().subscribe(stores => this.stores = stores);
    this.user = data.user;
    this.potentialStoreIds = data.potentialStoreIds
    this.formGroup = this.createFormGroup();

    this.storeService.getStores().subscribe((stores) => {
      this.stores = stores;
      this.filteredStores = stores; // Initialize filtered list
      this.checkPermissions();
    });
    if (this.singleStoreRestricted && this.user?.storeIds?.length) {
      this.formGroup.patchValue({ singleStoreSelection: this.user.storeIds[0] });
    }
    this.form = new AppForm(this.formGroup, this.user, new User(), user => this.onSuccess(user));
  }

  ngOnInit(): void {
    const currentUserId = this.authService.getCurrentUserId();
    this.userService.getUser(currentUserId).subscribe(fullUser => {
      this.currentUser = fullUser;
    });

    this.checkPermissions();

    // Subscribe to the search control and filter stores.
    this.storeSearchControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((searchTerm) => {
        const lowerSearchTerm = searchTerm?.toLowerCase() || '';
        // Filter based on the original allowed list.
        this.filteredHomeStores = (this.potentialStoreIds === 'unrestricted' ? this.selectedStores : this.filteredStores)
          .filter(store => store.name.toLowerCase().includes(lowerSearchTerm));
      });

    this.homeStoreSearchControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.updateFilteredHomeStores();
      });

    // Re-filter home stores whenever the multi-select changes
    this.formGroup.get('storeIds').valueChanges
      .subscribe(() => {
        this.updateFilteredHomeStores();
      });

    // Subscribe to other changes (organization, roles) as needed.
    this.formGroup.get('organizationId').valueChanges.subscribe(() => {
      this.checkPermissions();
    });
    this.formGroup.get('roles').valueChanges.subscribe(() => {
      this.checkPermissions();
    });

    this.toggleStoreSelection(); // Call it on init
  }

  toggleStoreSelection(): void {
    const storeFormControlName: string = this.singleStoreRestricted ? 'singleStoreSelection' : 'storeIds'

    const storeControl = this.formGroup.get(storeFormControlName);
    if (this.storeSetLoaded) {
      storeControl?.enable();
    } else {
      storeControl?.disable();
    }
  }

  checkPermissions(): void {
    const simulationParams = {
      organization_id: this.formGroup.get('organizationId').value,
      roles: this.formGroup.get('roles').value,
      ...(this.user && { user_id: this.user.id })
    };


    // Disable the store dropdown while waiting for the simulation response
    this.storeSetLoaded = false;
    this.toggleStoreSelection();

    this.userService.getPotentialStoreIds(simulationParams).subscribe(simulationResult => {
      let potential = simulationResult.potential_store_ids;
      if (typeof potential === 'string') {
        potential = potential.trim();
      }
      this.potentialStoreIds = potential;

      this.singleStoreRestricted = !!simulationResult.single_store_restricted;
      const storeFormControlName: string = this.singleStoreRestricted ? 'singleStoreSelection' : 'storeIds'

      // Compute the base allowed list.
      if (this.potentialStoreIds === 'unrestricted') {
        // If unrestricted, all stores are assignable.
        // Use slice() to clone the array, then sort alphabetically by name.
        this.assignableStores = this.stores.slice().sort((a, b) => a.name.localeCompare(b.name));
      } else if (Array.isArray(simulationResult.potential_store_ids)) {
        // Include stores that are either in the simulation result or already selected by the user.
        this.assignableStores = this.stores
          .filter(store =>
            simulationResult.potential_store_ids.includes(store.id)
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        const currentSelectedStores = this.formGroup.get(storeFormControlName)?.value;
        if (Array.isArray(currentSelectedStores)) {
          const updatedSelection = currentSelectedStores.filter(storeId =>
            this.assignableStores.some(store => store.id === storeId)
          );
          this.formGroup.patchValue({ [storeFormControlName]: updatedSelection });
        } else if (
          currentSelectedStores &&
          !this.assignableStores.some(store => store.id === currentSelectedStores)
        ) {
          this.formGroup.patchValue({ [storeFormControlName]: null });
        }
      } else {
        // Fallback if potentialStoreIds isn't recognized.
        this.assignableStores = [];
        this.formGroup.patchValue({ [storeFormControlName]: null })
      }
      this.filteredStores = this.assignableStores;
      this.updateFilteredHomeStores();

      // Mark that the simulation (and store set) has fully loaded
      this.storeSetLoaded = true;
      this.toggleStoreSelection();
    });
  }

  getFilteredHomeStores(): Store[] {
    return this.potentialStoreIds === 'unrestricted' ? this.selectedStores : this.filteredStores;
  }

  updateFilteredHomeStores(): void {
    let baseStores: Store[];

    if (this.potentialStoreIds === 'unrestricted') {
      const selectedOrgId = this.formGroup.get('organizationId')?.value;
      const selectedOrg = this.orgs.find(org => org.id === selectedOrgId);


      baseStores = (selectedOrg && selectedOrg.name === "Badger")
        ? this.assignableStores
        : this.assignableStores.filter(store => store.organizationId === selectedOrgId);
    } else {
      baseStores = this.selectedStores;
    }

    const searchTerm = this.homeStoreSearchControl.value?.toLowerCase() || '';
    this.filteredHomeStores = baseStores.filter(store =>
      store.name.toLowerCase().includes(searchTerm)
    );
  }

  create(): void {
    this.unifyStoreIds();
    this.form.submit(user => this.userService.createUser(user));
  }

  update(): void {
    this.unifyStoreIds();
    this.form.submit(user => {
      console.log("Payload before removing storeIds", user);
      // Now see if potentialStoreIds is 'unrestricted'
      if (this.potentialStoreIds === 'unrestricted') {
        delete user.storeIds;
        // or: user.storeIds = [];
      }
      console.log("Payload after removing storeIds", user);

      return this.userService.updateUser(user);
    });
  }

  private unifyStoreIds(): void {
    if (this.singleStoreRestricted) {
      // singleStoreSelection is the single store ID or null
      this.formGroup.patchValue({
        storeIds: [this.formGroup.get('singleStoreSelection')?.value]
      })
      this.formGroup.get('storeIds')?.enable({ emitEvent: false });
    }

    // Get the typed User instance from your form
    const user = this.form.model as User;

    // Set the unrestricted flag
    user.unrestricted = (this.potentialStoreIds === 'unrestricted');

    // Reset homeStoreId if it is no longer a valid selection
    const selectedHomeStoreId = this.formGroup.get('homeStoreId')?.value;
    if (!this.assignableStores.some(store => store.id === selectedHomeStoreId)) {
      this.formGroup.patchValue({ homeStoreId: null });
    }
  }

  onSingleStoreSelection(event: any): void {
    const selectedStoreId = event.value;

    // Patch storeIds and singleStoreSelection
    this.formGroup.patchValue({
      storeIds: [selectedStoreId],
      singleStoreSelection: selectedStoreId
    }, { emitEvent: true });
  }


  createFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      email: new UntypedFormControl(this.user.email, Validators.email),
      firstName: new UntypedFormControl(this.user.firstName, [Validators.required, this.notWhitespaceValidator()]),
      lastName: new UntypedFormControl(this.user.lastName, [Validators.required, this.notWhitespaceValidator()]),
      organizationId: new UntypedFormControl(this.user.organizationId),
      homeStoreId: new UntypedFormControl(this.user.homeStoreId),
      storeIds: new UntypedFormControl(this.user?.storeIds || []),
      singleStoreSelection: new UntypedFormControl((this.user?.storeIds && this.user?.storeIds.length > 0) ? this.user.storeIds[0] : null),
      roles: new UntypedFormControl(this.user?.roles || [])
    });
  }

  filterStores(searchTerm: string): Store[] {
    const lowerSearchTerm = searchTerm?.toLowerCase() || '';
    return this.filteredStores.filter((store) =>
      store.name.toLowerCase().includes(lowerSearchTerm)
    );
  }

  onSuccess(user: User) {
    user.organization = this.orgs.find(org => org.id === user.organizationId);
    user.store = this.stores.find(store => store.id === user.homeStoreId);
    this.dialogRef.close(user);
  }

  notWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace ? { 'whitespace': true } : null;
    };
  }

  get selectedStores(): Store[] {
    const selectedStoreIds = this.formGroup.get('storeIds')?.value || [];
    return this.stores.filter(store => selectedStoreIds.includes(store.id));
  }

  get selectedOrg() {
    const selectedOrgId = this.formGroup.get('organizationId')?.value;
    return this.orgs.find(org => org.id === selectedOrgId);
  }

}
