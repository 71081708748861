import { AbstractApiModel } from './abstract-api-model';
import { Map } from './map';
import { Organization } from './organization';
import { HasOrganization } from './interfaces/has-organization';
import { Robot } from './robot';
import { Play } from './play';
import * as _ from 'lodash';

export class Store extends AbstractApiModel<Store> implements HasOrganization {
  name: string;
  ipAddress: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  smsAlertPhone: string;
  clientStoreId: string;
  map: Map;
  organizationId: number;
  aholdPaType: string;
  countryCode: string;
  timezone: string;
  solutions: string[];
  organization: Organization;
  robots: Robot[];
  captureOnly: boolean;
  autoAcceptConfidentHoles: boolean;
  saltConfiguration: any;

  static readonly defaultPlayTypes: string[] = [Play.TYPE_INSPECT, Play.TYPE_INSIGHT, Play.TYPE_FOCUS];
  activeTaskCount: number;

  deserialize(json: any): this {
    this.name = json.name;
    this.clientStoreId = json.client_store_id;
    this.address = json.address;
    this.city = json.city;
    this.state = json.state;
    this.zipCode = json.zip_code;
    this.phone = json.phone;
    this.smsAlertPhone = json.sms_alert_phone;
    this.ipAddress = json.ip_address;
    this.map = new Map();
    this.organizationId = json.organization_id;
    this.aholdPaType = json.ahold_pa_type;
    this.countryCode = json.country_code;
    this.timezone = json.timezone;
    this.robots = [];
    this.solutions = json.solutions;
    this.captureOnly = json.capture_only;
    this.autoAcceptConfidentHoles = json.auto_accept_confident_holes;
    this.saltConfiguration = json.salt_config;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name,
      client_store_id: this.clientStoreId,
      address: this.address,
      city: this.city,
      state: this.state,
      zip_code: this.zipCode,
      phone: this.phone,
      sms_alert_phone: this.smsAlertPhone,
      ip_address: this.ipAddress,
      organization_id: this.organizationId,
      ahold_pa_type: this.aholdPaType,
      country_code: this.countryCode,
      timezone: this.timezone,
      solutions: this.solutions,
      capture_only: this.captureOnly,
      auto_accept_confident_holes: this.autoAcceptConfidentHoles,
      salt_config: this.saltConfiguration
    });
  }

  topStockEnabled(): boolean {
    return !_.isNil(this.solutions) &&
      !_.isNil(this.solutions.find(solution => solution == 'top_stock'));
  }

  rfidEnabled(): boolean {
    if (_.isNil(this.robots)) {
      return false;
    }
    for (const robot of this.robots) {
      if (!_.isNil(robot.robotModel?.features) && robot.robotModel?.features.includes('RFID')) {
        return true;
      }
    }
    return false
  }

  playTypes(): string[] {
    const playTypes: string[] = _.clone(Store.defaultPlayTypes);
    if (this.rfidEnabled()) {
      playTypes.push(Play.TYPE_RFID);
    }
    return playTypes;
  }
}
