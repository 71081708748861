import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ClientSideDataTable, Column, Commentable } from '@ng-cloud/badger-core';

@Component({
    selector: 'bt-commentable-list',
    templateUrl: './commentable-list.component.html',
    styleUrls: ['./commentable-list.component.scss'],
    standalone: false
})
export class CommentableListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('table') table;
  @ViewChildren('matRow', { read: ViewContainerRef }) rows: QueryList<ViewContainerRef>;

  @Input() commentables: Commentable[];
  @Input() initialSelection: Commentable;

  @Output() commentableSelect = new EventEmitter();

  textFilter: UntypedFormControl = new UntypedFormControl();
  commentableTable: ClientSideDataTable<Commentable>;
  commentableSelection = new SelectionModel<Commentable>(false, []);
  selectedCommentable: Commentable;
  displayedColumns: string[];
  commentableType: string;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.createCommentableTable();
    this.commentableTable.data = this.commentables;
    this.commentableTable.sort = this.sort;
    if (this.commentables.length) {
      this.commentableType = this.commentables[0].getCommentableType();
    }
  }

  ngAfterViewInit() {
    if (this.commentables.length) {
      this.commentableTable.connect().subscribe(sortedData => {
        this.selectCommentable(this.initialSelection ? this.initialSelection : sortedData[0]);
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  protected createCommentableTable() {
    const columnDefs: Column[] = [];
    columnDefs.push(new Column('name'));
    this.commentableTable = new ClientSideDataTable<Commentable>(columnDefs);

    this.createCommentableTableFilter();
  }

  getDisplayedColumns() {
    const columns: string[] = [];
    columns.push('name');
    return columns;
  }

  protected createCommentableTableFilter() {
    this.commentableTable.filter = new UntypedFormGroup({
      filter: this.textFilter
    });

    this.commentableTable.dataSource.filterPredicate = (data: Commentable, filter: string) => this.filterData(data, filter);
  }

  clearInputTextField() {
    this.commentableTable.filter.get('filter').reset();
  }

  protected filterData(commentable: Commentable, json: string) {
    const filters = JSON.parse(json);
    const textFilter = filters['filter'];

    if (textFilter) {
      if (!this.commentableTable.columnDefs.some(col => col.filterValue(commentable).includes(textFilter.toLowerCase()))) {
        return false;
      }
    }

    return true;
  }

  selectCommentable(commentable: Commentable) {
    this.commentableSelection.select(commentable);
    this.selectedCommentable = commentable;
    this.commentableSelect.emit(commentable);

    if (commentable) {
      this.showElement(commentable.id);
    }
  }

  showElement(commentableId: number) {
    const selectedRow = this.rows.find(row => row.element.nativeElement.getAttribute('commentableId') === commentableId.toString());

    if (selectedRow) {
      const rowRect = selectedRow.element.nativeElement.getBoundingClientRect();
      const table = this.table._elementRef.nativeElement;
      const tableRect = table.getBoundingClientRect();
      const tableHeaderHeight = table.firstElementChild ? table.firstElementChild.clientHeight : 0;

      if ((rowRect.y < (tableRect.y + tableHeaderHeight)) || (rowRect.bottom > tableRect.bottom)) {
        table.scrollTop = selectedRow.element.nativeElement.offsetTop - table.offsetTop - tableHeaderHeight;
      }
    }
  }
}
