import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '../environments/environment';
import {
  AnalyticsService,
  Api2Service,
  ApiService,
  AuthorizationService,
  CustomIconService,
  ErrorService,
  MessageService,
  OrganizationService,
  PlayExecutionSearchComponent,
  TitleService,
  UserService
} from '@ng-cloud/badger-core';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { ConfigureDashboardsComponent } from './analytics-common/configure-dashboards/configure-dashboards.component';
import { TopFilterService } from './analytics-common/services/top-filter.service';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'badger-portal-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private resizeObservable: ResizeObserver;
  private _navMenuButton: MatButton;
  @ViewChild('navMenuTrigger') navMenuTrigger: MatMenuTrigger;
  @ViewChild('searchButton') searchButton: MatButton;

  @ViewChild('navMenuButton') set navMenuButton(navMenuButton: MatButton) {
    this._navMenuButton = navMenuButton;
    if (this._navMenuButton) {
      this.resizeObservable.observe(this._navMenuButton._elementRef.nativeElement);
    }
  }

  title = 'Portal';
  hasInsightAnalyticsAccess = false;
  hasPointOfSaleReportsAccess = false;
  isInsightMod = false;
  orgType = 'retail';

  constructor(
    public authService: AuthorizationService,
    private messageService: MessageService,
    private orgService: OrganizationService,
    private errorService: ErrorService,
    private titleService: TitleService,
    private customIconService: CustomIconService,
    private userService: UserService,
    public dialog: MatDialog,
    public searchDialog: MatDialog,
    private analyticsService: AnalyticsService,
    private topFiltersService: TopFilterService
  ) {
    TitleService.appTitle = this.title;
    ApiService.apis = environment.apis;

    Api2Service.apiBase = environment.apiBaseAnalytics;
    Api2Service.apiPath = environment.apiPathAnalytics;

    this.customIconService.addCustomIcon('insight_marty', '../assets/insight_marty.svg');
    this.customIconService.addCustomIcon('inspect_marty', '../assets/inspect_marty.svg');

    this.customIconService.addCustomIcon('badger-light', '../assets/badger_dark.svg');
    this.customIconService.addCustomIcon('badger-dark', '../assets/badger_dark.svg');

    this.customIconService.addCustomIcon('barcode', '../assets/barcode.svg');
    this.customIconService.addCustomIcon('badger_logo', '../assets/badger_logo.svg');
    this.customIconService.addCustomIcon('insight', '../assets/insight.svg');
    this.customIconService.addCustomIcon('inspect', '../assets/inspect.svg');
    this.customIconService.addCustomIcon('camera', '../assets/camera.svg');
    this.customIconService.addCustomIcon('arrow', '../assets/arrow.svg');

    this.customIconService.addCustomIcon('price_mismatch_alt', '../assets/price_alt.svg');
    this.customIconService.addCustomIcon('sale_price_mismatch_alt', '../assets/sale_price_alt.svg');
    this.customIconService.addCustomIcon('top_stock_alt', '../assets/top_stock_alt.svg');
    this.customIconService.addCustomIcon('hole_oos_alt', '../assets/out_alt.svg');
    this.customIconService.addCustomIcon('out_of_stock_alt', '../assets/out_alt.svg');
    this.customIconService.addCustomIcon('eOOS_alt', '../assets/wrong_alt.svg');
    this.customIconService.addCustomIcon('viewport_alt', '../assets/viewport_alt.svg');
    this.customIconService.addCustomIcon('viewport_issue_alt', '../assets/viewport_alt.svg');
    this.customIconService.addCustomIcon('unconfirmed_product_alt', '../assets/unconfirmed_product_alt.svg');

    this.customIconService.addCustomIcon('price_mismatch', '../assets/price.svg');
    this.customIconService.addCustomIcon('sale_price_mismatch', '../assets/sale_price.svg');
    this.customIconService.addCustomIcon('hole_oos', '../assets/out.svg');
    this.customIconService.addCustomIcon('out_of_stock', '../assets/out.svg');
    this.customIconService.addCustomIcon('eOOS', '../assets/wrong.svg');
    this.customIconService.addCustomIcon('viewport', '../assets/viewport.svg');
    this.customIconService.addCustomIcon('viewport_issue', '../assets/viewport.svg');
    this.customIconService.addCustomIcon('unconfirmed_product', '../assets/unconfirmed_product.svg');
    this.customIconService.addCustomIcon('shelf_stock', '../assets/shelf_stock.svg');
    this.customIconService.addCustomIcon('topstock', '../assets/topstock.svg');
    this.customIconService.addCustomIcon('topstock_detected', '../assets/topstock_detected.svg');

    this.customIconService.addCustomIcon('multiview', '../assets/multiview-icon.svg');
    this.customIconService.addCustomIcon('wideview', '../assets/wideview-icon.svg');
    this.customIconService.addCustomIcon('image_search', '../assets/image_search.svg');

    this.customIconService.addCustomIcon('no_issue', '../assets/no_issue.svg');

    this.customIconService.addCustomIcon('add_tag', '../assets/add_tag.svg');
    this.customIconService.addCustomIcon('enable_tags', '../assets/enable_tags.svg');
    this.customIconService.addCustomIcon('disable_tags', '../assets/disable_tags.svg');
    this.customIconService.addCustomIcon('create_region', '../assets/create_region.svg');
    this.customIconService.addCustomIcon('no_shelf_region', '../assets/no_shelf_region.svg');
    this.customIconService.addCustomIcon('pricing_only_region', '../assets/pricing_only_region.svg');
    this.customIconService.addCustomIcon('ignore_region', '../assets/ignore_region.svg');

    this.customIconService.addCustomIcon('tag_top_left', '../assets/tag_top_left.svg');
    this.customIconService.addCustomIcon('tag_top_center', '../assets/tag_top_center.svg');
    this.customIconService.addCustomIcon('tag_top_right', '../assets/tag_top_right.svg');
    this.customIconService.addCustomIcon('tag_bottom_left', '../assets/tag_bottom_left.svg');
    this.customIconService.addCustomIcon('tag_bottom_center', '../assets/tag_bottom_center.svg');
    this.customIconService.addCustomIcon('tag_bottom_right', '../assets/tag_bottom_right.svg');
    this.customIconService.addCustomIcon('tag_middle_left', '../assets/tag_middle_left.svg');
    this.customIconService.addCustomIcon('tag_middle_center', '../assets/tag_middle_center.svg');
    this.customIconService.addCustomIcon('tag_middle_right', '../assets/tag_middle_right.svg');
    this.customIconService.addCustomIcon('image_not_supported', '../assets/image_not_supported.svg');
    this.customIconService.addCustomIcon('csv_file', '../assets/csv_file.svg');
    this.customIconService.addCustomIcon('drag_vert', '../assets/drag_vert.svg');
    this.customIconService.addCustomIcon('pos', '../assets/pos.svg');
  }

  ngOnInit() {
    this.authService.currentRoles().subscribe(roles => {
      if (roles.includes('insight_analytics') || roles.includes('user') || roles.includes('client_portal')) {
        if (roles.includes('insight_analytics')) {
          this.hasInsightAnalyticsAccess = true;
        }
        if (roles.includes('user') || roles.includes('client_portal')) {
          this.hasPointOfSaleReportsAccess = true;
        }
        this.authService.getCurrentUser().pipe(
          mergeMap(user => {
            if (user.organization_id) {
              return this.orgService.getOrganization(user.organization_id);
            }
            else {
              return of(null);
            }
          })
        ).pipe(
          mergeMap(org => {
            if (org) {
              this.orgType = org.orgType;
              if (roles.includes('insight_analytics')) {
                this.topFiltersService.setOrgType(this.orgType);
                return this.analyticsService.getDashboardConfiguration(this.orgType);
              }
            }
            return of(null);
          })
        )
          .subscribe(config => this.topFiltersService.setDashboardsConfig(config, false));
      }
      if (roles.includes('insight_moderator') && !roles.includes('admin')) {
        this.isInsightMod = true;
      }
    });

    this.errorService.exceptions().subscribe(exception => {
      this.messageService.alert(exception.error, exception);
    });

    this.errorService.alerts().subscribe(alert => {
      this.messageService.alert(alert);
    });

    this.userService.getConfigureDashboard()
      .subscribe(() => {
        this.configureDashboardClick();
      });
  }

  ngOnDestroy(): void {
    this.resizeObservable?.disconnect();
  }

  ngAfterViewInit(): void {
    this.createResizeObservable();
  }

  createResizeObservable() {
    this.resizeObservable = new ResizeObserver(entries => {
      if (entries[0].contentRect.width === 0) {
        this.navMenuTrigger.closeMenu();
      }
    });
  }

  configureDashboardClick() {
    const dialogRef = this.dialog.open(ConfigureDashboardsComponent, {
      data: {}
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  openSearchDialog() {
    const dialogRef = this.searchDialog.open(PlayExecutionSearchComponent, {
      data: {},
      height: 'auto',
      width: 'auto',
      position: { right: '75px', top: '60px' }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }
}
