import { Injectable } from '@angular/core';

import { ApiService } from '@ng-cloud/badger-core/services/api.service';
import { StoreSchedule } from '@ng-cloud/badger-core/models/store-schedule';
import { StoreScheduleEvent } from '@ng-cloud/badger-core/models/store-schedule-event';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends ApiService {

  /**
   * Get all Schedule status names
   */
  getScheduleStatusNames(): string[] {
    return [
      'on_hold',
      'active'
    ];
  }

  /**
   * Fetch all store schedules for the given store id
   */
  getSchedules(storeId: number, params?: any): Observable<StoreSchedule[]> {
    return this.list(StoreSchedule, `stores/${storeId}/store_schedules`, params, 0);
  }

  getSchedule(id: number): Observable<StoreSchedule> {
    return this.get(StoreSchedule, `store_schedules/${id}`, 600);
  }

  createSchedule(schedule: StoreSchedule): Observable<StoreSchedule> {
    return this.create(schedule, `stores/${schedule.storeId}/store_schedules`);
  }

  updateSchedule(schedule: StoreSchedule): Observable<StoreSchedule> {
    return this.update(schedule, `store_schedules/${schedule.id}`);
  }

  deleteSchedule(schedule: StoreSchedule): Observable<StoreSchedule> {
    return this.destroy(schedule, `store_schedules/${schedule.id}`);
  }

  /**
   * StoreScheduleEvent
   */
  getScheduleEvents(scheduleId: number, params?: any): Observable<StoreScheduleEvent[]> {
    return this.list(StoreScheduleEvent, `store_schedules/${scheduleId}/store_schedule_events`, params, 600);
  }

  getScheduleEvent(id: number): Observable<StoreScheduleEvent> {
    return this.get(StoreScheduleEvent, `store_schedule_events/${id}`, 600);
  }

  runScheduleEvent(scheduleEvent: StoreScheduleEvent): Observable<StoreScheduleEvent> {
    return this.create(scheduleEvent, `store_schedule_events/${scheduleEvent.id}/run`);
  }

  createScheduleEvent(scheduleEvent: StoreScheduleEvent): Observable<StoreScheduleEvent> {
    return this.create(scheduleEvent, `store_schedules/${scheduleEvent.storeScheduleId}/store_schedule_events`);
  }

  updateScheduleEvent(scheduleEvent: StoreScheduleEvent): Observable<StoreScheduleEvent> {
    return this.update(scheduleEvent, `store_schedule_events/${scheduleEvent.id}`);
  }

  deleteScheduleEvent(scheduleEvent: StoreScheduleEvent): Observable<StoreScheduleEvent> {
    return this.destroy(scheduleEvent, `store_schedule_events/${scheduleEvent.id}`);
  }

}
