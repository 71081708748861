<span mat-dialog-title>{{ commentable ? commentable.getDisplayName() : 'Comment' }}</span>
<form [formGroup]="formGroup" (ngSubmit)="create()">
  <mat-dialog-content>
    <bt-form-errors></bt-form-errors>
    <bt-form-field>
      <mat-form-field color="accent" appearance="outline" class="comment-content">
        <mat-label>Comment</mat-label>
        <textarea matInput rows="5" name="body" formControlName="body" required style="resize: none"></textarea>
      </mat-form-field>
      <bt-field-errors></bt-field-errors>
    </bt-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button [matDialogClose]="">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="formGroup.invalid || (commentable == null)"> Save</button>
  </mat-dialog-actions>
</form>
