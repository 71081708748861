import { Pose } from './pose';
import { AbstractApiModel } from './abstract-api-model';
import * as _ from 'lodash';
import { HasPose } from './interfaces/has-pose';
import { Path } from './path';
import { InspectPoseAssessment } from './inspect-pose-assessment';
import {ScanZone} from './scan-zone';

export class PlaySequence extends AbstractApiModel<PlaySequence> implements HasPose {
  static readonly POSE_TYPE = 'Pose';
  static readonly PATH_TYPE = 'Path';
  static readonly SCAN_ZONE_TYPE = 'ScanZone';

  type: string;
  priority: number;
  pose: Pose;
  path: Path;
  scanZone: ScanZone;
  inspectPoseAssessments: InspectPoseAssessment [] = [];
  currentAssessmentIdentifiers: string [] = [];

  resetCurrentAssessmentIdentifiers(identifiers: string[]) {
    this.currentAssessmentIdentifiers = identifiers.slice(0);
  }

  deserialize(json: any): this {
    this.type = json.whereabout_type;
    this.priority = json.priority;

    if (json.inspect_pose_assessments) {
      json.inspect_pose_assessments.forEach((assessment) => {
        this.inspectPoseAssessments.push(new InspectPoseAssessment().deserialize(assessment));
      });

      this.inspectPoseAssessments.forEach(assessment => {
        this.currentAssessmentIdentifiers.push(assessment.inspectAssessmentIdentifier);
      });
    }

    if (this.type == PlaySequence.POSE_TYPE) {
      this.pose = new Pose().deserialize(json.whereabout);
    }
    if (this.type == PlaySequence.PATH_TYPE) {
      this.path = new Path().deserialize(json.whereabout);
    }
    if (this.type == PlaySequence.SCAN_ZONE_TYPE) {
      this.scanZone = new ScanZone().deserialize(json.whereabout);
    }
    return super.deserialize(json);
  }

  serialize(): any {
    return {
      inspect_pose_assessments_attributes: _.map(this.inspectPoseAssessments, assessment => assessment.serialize()),
      priority: this.priority,
      whereabout_type: this.type,
      whereabout_attributes: this[_.camelCase(this.type)].serialize()
    };
  }

  sortValue(): number {
    return this.priority;
  }
}
