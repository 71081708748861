import { takeWhile } from 'rxjs/operators';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { StoreService } from '../../services/store.service';
import { Play } from '../../models/play';
import { Map } from '../../models/map';
import { MapComponent } from '../../map/map.component';
import { PlaySequence } from '../../models/play-sequence';
import { PlayService } from '../../services/play.service';
import { PoseGrouping } from '../../map/groupings/pose-grouping';
import { RouteGrouping } from '../../map/groupings/route-grouping';
import { PathGrouping } from '../../map/groupings/path-grouping';
import { ScanZoneGrouping } from '../../map/groupings/scan-zone-grouping';

@Component({
    selector: 'bt-play-map',
    templateUrl: './play-map.component.html',
    styleUrls: ['./play-map.component.scss'],
    standalone: false
})
export class PlayMapComponent implements OnInit, OnDestroy, OnChanges {
  @Input() playId: number;
  @Input() size: string;
  @Input() zoom: string | number;
  storeMap: Map;
  play: Play;

  mapComponent: MapComponent;
  sequenceGrouping: PoseGrouping<PlaySequence> = new PoseGrouping({
    hasFirstColor: true,
    hasLastColor: true
  });
  routeGrouping: RouteGrouping = new RouteGrouping([this.sequenceGrouping], {
    strokeWidth: 6
  });
  pathGrouping: PathGrouping = new PathGrouping();
  scanZoneGrouping: ScanZoneGrouping = new ScanZoneGrouping();

  private alive = true;

  constructor(
    protected playService: PlayService,
    protected storeService: StoreService
  ) {
    this.routeGrouping.changed().pipe(
      takeWhile(() => this.alive))
      .subscribe(() => this.mapComponent && this.mapComponent.render());
  }

  ngOnInit() {
    this.playService.getPlay(this.playId).subscribe(play => {
      this.play = play;
      if (play.playType == Play.TYPE_INSPECT) {
        this.sequenceGrouping.setData(this.play.getPlaySequences());
        this.pathGrouping.setData([]);
        this.scanZoneGrouping.setData([]);
      }
      else if (play.playType == Play.TYPE_RFID) {
        this.scanZoneGrouping.setData(this.play.getPlaySequences().map(sequence => sequence.scanZone));
        this.sequenceGrouping.setData([]);
        this.pathGrouping.setData([]);
      }
      else if (play.playType != Play.TYPE_INSPECT) {
        this.pathGrouping.setData(this.play.getPlaySequences().map(sequence => sequence.path));
        this.sequenceGrouping.setData([]);
        this.scanZoneGrouping.setData([]);
      }

      this.storeService.getMap(this.play.storeId).subscribe(map => {
        this.sequenceGrouping.setStoreMap(map);
        this.storeMap = map;
      });
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngOnChanges() {
    this.playService.getPlay(this.playId).subscribe(play => {
      this.play = play;
      this.sequenceGrouping.setData(this.play.getPlaySequences());
    });
  }

  // Initially render play and add any global event handlers
  onMapLoad(mapComponent: MapComponent) {
    this.mapComponent = mapComponent;
    this.mapComponent.addGrouping(this.routeGrouping);
    this.mapComponent.addGrouping(this.sequenceGrouping);
    this.mapComponent.addGrouping(this.pathGrouping);
    this.mapComponent.addGrouping(this.scanZoneGrouping);
    this.mapComponent.render();
  }
}
