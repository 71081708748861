import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { CaptureEvent } from '../../models/capture-event';
import { CaptureEventViewReview } from '../../models/capture-event-view-review';
import * as _ from 'lodash';
import { RobotService } from '../../services/robot.service';

@Component({
    selector: 'bt-capture-event-review-detail',
    templateUrl: './capture-event-review-detail.component.html',
    styleUrls: ['./capture-event-review-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CaptureEventReviewDetailComponent implements OnChanges {
  @ContentChild(TemplateRef) reviewDetailTemplate;
  @Input() captureEvent: CaptureEvent;
  review: CaptureEventViewReview;
  activeTab: number;
  @Output() close = new EventEmitter();

  constructor(
    private robotService: RobotService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const captureEventChange = changes['captureEvent'];
    if (_.get(captureEventChange, 'currentValue.id') !== _.get(captureEventChange, 'previousValue.id')) {
      this.selectReview(_.get(this.captureEvent, ['views', '0', 'reviews', '0', 'id'])); // Select the first review
      this.activeTab = 0; // Reset to the first review tab
    }
  }

  onClose() {
    this.close.emit(null);
  }

  selectReview(id: number) {
    if (id) {
      this.robotService.getCaptureEventReview(id).subscribe(review => this.review = review);
    }
    else {
      this.review = null;
    }
  }

  getImageLabel(camera_label: any) {
    return _.replace(camera_label, 'clean_sweep_', '');
  }
}
