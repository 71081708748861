import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { User } from '../models/user';
import { Observable, Subject } from 'rxjs';
import { UserPreference } from '@ng-cloud/badger-core/models/user-preference';

@Injectable()
export class UserService extends ApiService {

  private configureDashboard = new Subject<void>();

  private currentUserHomeStoreId: number | null = null;


  setCurrentUserHomeStoreId(storeId: number) {
    this.currentUserHomeStoreId = storeId;
  }

  getCurrentUserHomeStoreId(): number | null {
    return this.currentUserHomeStoreId;
  }

  /**
   * Fetch all Users
   */
  getUsers(params?: any): Observable<User[]> {
    return this.list(User, 'users', params);
  }

  /**
   * Fetch User data for the given id
   */
  getUser(id: number, params?: any): Observable<User> {
    return this.get(User, `users/${id}`, 300, params);
  }

  /**
   *  Get User Preferences
   */
  getUserPreference(userId: number, params?: any): Observable<UserPreference> {
    return this.get(UserPreference, `users/${userId}/user_preference`, 0, params);
  }

  /**
  * Listen to user preference channel for updates
  **/
  userPreferences(userId: number): Observable<UserPreference> {
    return this.cable().channel('UserPreferenceUpdatedChannel', { user_id: userId })
      .received().pipe(map(response => JSON.parse(response)));
  }

  /**
   * Fetch potential allowed store data for the given user params.
   * Expects an object with at least { organization_id, roles }.
   */
  getPotentialStoreIds(params: any): Observable<any> {
    // Using http.post since the simulation endpoint is a POST.
    return this.http.post(this.url('users/simulate_store_permissions'), { user: params });
  }

  /**
   * Create User
   */
  createUser(user: User): Observable<User> {
    return this.create(user, 'users');
  }

  /**
   *  Create User Preferences
   */
  createUserPreference(userPreference: UserPreference): Observable<UserPreference> {
    return this.create(userPreference, `users/${userPreference.userId}/user_preferences`);
  }

  /**
   * Update User
   */
  updateUser(user: User): Observable<User> {
    console.log("Final request payload before HTTP request:", user);
    return this.update(user, `users/${user.id}`);
  }

  /**
   * Update User HomeStore
   */
  updateUserHomeStore(user: User) :Observable<User> {
    return this.update(user, `users/${user.id}/update_home_store`);
  }

  /**
   * Update User Preferences
   */
  updateUserPreference(userPreference: UserPreference): Observable<UserPreference> {
    return this.update(userPreference, `users/${userPreference.userId}/user_preference`);
  }

  /**
   * Delete User
   */
  deleteUser(user: User): Observable<any> {
    return this.destroy(user, `users/${user.id}`);
  }

  getRoles(): Observable<string[]> {
    return this.http.get(this.url('users/roles')).pipe(
      filter(response => response != null)
    ) as Observable<string[]>;
  }

  getConfigureDashboard(): Observable<void> {
    return this.configureDashboard.asObservable();
  }

  triggerConfigureDashboard() {
    this.configureDashboard.next();
  }

}
