<bt-navbar #navbar [title]="title">
  <div fxLayout="row" fxLayoutAlign="start center" signedInLeftAlign>
    <bt-age-queue-chips></bt-age-queue-chips>
    <bt-queue-chips *hasAccess="['admin', 'skybox_level_1']"></bt-queue-chips>
    <bt-insight-queue-chips [componentBreakpoint]="'gt-lg'" [showOrgSwitcher]="isInsightMod" class="insight-queue-chips"
                            *hasAccess="['insight_moderator', 'admin', 'insight_auditor']"></bt-insight-queue-chips>
  </div>

  <ng-container signedInRightAlign>
    <a *hasAccess="['user', 'insight_auditor', 'external_insight_auditor', 'realogram_editor']" mat-button
       routerLink="stores">Stores</a>
    <a *ngIf="hasInsightAnalyticsAccess && (orgType == 'fmcg')" mat-button routerLink="analytics">Analytics</a>
    <a *ngIf="hasInsightAnalyticsAccess && (orgType == 'retail')" mat-button routerLink="analytics-retail">Analytics</a>
    <a *ngIf="hasPointOfSaleReportsAccess && (orgType == 'fmcg')" mat-button routerLink="reports">Reports</a>
    <a *hasAccess="['admin', 'realogram_editor']" mat-button routerLink="queued-base-scans">Base Scans</a>
    <button #searchButton mat-icon-button (click)="openSearchDialog()" title="Search...">
      <mat-icon>search</mat-icon>
    </button>
  </ng-container>

  <ng-container smallScreenItems>
    <button #navMenuButton *ngIf="authService.hasCurrentUser()" mat-icon-button #navMenuTrigger="matMenuTrigger"
            [mat-menu-trigger-for]="menu" fxHide="false"
            fxHide.gt-md>
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu xPosition="before" style="width: 400px !important;">
      <a *hasAccess="['user', 'insight_auditor', 'external_insight_auditor', 'realogram_editor']" mat-menu-item
         routerLink="/stores">Stores</a>
      <a *ngIf="hasInsightAnalyticsAccess && (orgType == 'fmcg')" mat-menu-item routerLink="analytics">Analytics</a>
      <a *ngIf="hasInsightAnalyticsAccess && (orgType == 'retail')" mat-menu-item routerLink="analytics-retail">Analytics</a>
      <a *ngIf="hasPointOfSaleReportsAccess && (orgType == 'fmcg')" mat-menu-item routerLink="reports">Reports</a>
      <a *hasAccess="['admin', 'realogram_editor']" mat-menu-item
         routerLink="/queued-base-scans">Base Scans</a>
      <a mat-menu-item (click)="openSearchDialog()">Search</a>
      <a mat-menu-item routerLink="users/profile">Profile</a>
      <button mat-menu-item (click)="navbar.signOut()">Sign Out</button>
    </mat-menu>
  </ng-container>

</bt-navbar>

<main class="content-container">
  <router-outlet></router-outlet>
</main>
