import { finalize, map, take, tap } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AppForm,
  AuthorizationService,
  CaptureEvent,
  ClientSideDataTable,
  Column,
  DialogService,
  InsightService,
  InspectAssessmentService,
  PlayExecution,
  PlayService,
  RobotService,
  ScanEvent,
  Store,
  StoreService,
  TimezoneService,
  TitleService
} from '@ng-cloud/badger-core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { saveAs } from 'file-saver';

@Component({
    selector: 'bt-play-execution-search',
    templateUrl: './play-execution-search.component.html',
    styleUrls: ['./play-execution-search.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PlayExecutionSearchComponent implements OnInit {
  private static HISTORY_SIZE = 10;

  private hasAccess = false;
  protected loading = false;
  protected generatingReport = false;

  searchFormGroup: UntypedFormGroup;

  playExecutionIdFormGroup: UntypedFormGroup;
  playExecutionForm: AppForm<any>;

  scanEventIdFormGroup: UntypedFormGroup;
  scanEventForm: AppForm<any>;

  captureEventIdFormGroup: UntypedFormGroup;
  captureEventForm: AppForm<any>;

  playExecutionDataTable: ClientSideDataTable<PlayExecution>;
  static playExecutions: PlayExecution[] = [];

  scanEventDataTable: ClientSideDataTable<{ scanEventId: number, playExecution: PlayExecution }>;
  static scanEvents: { scanEventId: number, playExecution: PlayExecution }[] = [];

  captureEventDataTable: ClientSideDataTable<{ captureEventId: number, playExecution: PlayExecution }>;
  static captureEvents: { captureEventId: number, playExecution: PlayExecution }[] = [];

  idsGenerating: number[] = [];

  playExecutionNoResults = false;
  scanEventNoResults = false;
  captureEventNoResults = false;

  constructor(
    public media: MediaObserver,
    private storeService: StoreService,
    private playService: PlayService,
    private reviewService: InspectAssessmentService,
    private robotService: RobotService,
    private insightService: InsightService,
    private titleService: TitleService,
    protected dialogService: DialogService,
    private authorizationService: AuthorizationService,
    private timezoneService: TimezoneService,
    private router: Router) {
  }

  ngOnInit() {
    this.authorizationService.accessToAny(['admin']).pipe(take(1)).subscribe(hasAccess => {
      this.hasAccess = hasAccess;
      this.createPlayExecutionsTable();
      this.createScanEventsTable();
      this.createCaptureEventsTable();
      this.createFormGroups();

      this.playExecutionForm = new AppForm(this.playExecutionIdFormGroup, [], {}, playExecution => this.addPlayExecutions(playExecution));
      this.scanEventForm = new AppForm(this.scanEventIdFormGroup, [], {}, result => this.addScanEvent(result.playExecutions[0], result.scanEventId));
      this.captureEventForm = new AppForm(this.captureEventIdFormGroup, [], {}, result => this.addCaptureEvent(result.playExecutions[0], result.captureEventId));

      this.playExecutionIdFormGroup.valueChanges.subscribe(() => this.playExecutionNoResults = false);
      this.scanEventIdFormGroup.valueChanges.subscribe(() => this.scanEventNoResults = false);
      this.captureEventIdFormGroup.valueChanges.subscribe(() => this.captureEventNoResults = false);
    });
  }

  searchByPlayExecutionId() {
    this.loading = true;
    this.playExecutionNoResults = false;
    this.playExecutionForm.submit(form => this.robotService.getPlayExecutions({ 'id': form.id }).pipe(
        tap(playExecutions => {
          if (playExecutions[0]) {
            this.fetchSupportingData(playExecutions[0]);
            this.captureEventIdFormGroup.reset();
          }
        })));
  }

  searchByScanEventId() {
    this.loading = true;
    this.scanEventNoResults = false;
    this.scanEventForm.submit(form => this.robotService.getPlayExecutions(form).pipe(
      tap(playExecutions => {
        if (playExecutions[0]) {
          this.fetchSupportingScanEventData(playExecutions[0], form.scan_event_id);
          this.scanEventIdFormGroup.reset();
        }
      }),
      map(playExecutions => ({ playExecutions: playExecutions, scanEventId: form.scan_event_id })))
    );
  }

  searchByCaptureId() {
    this.loading = true;
    this.captureEventNoResults = false;
    this.captureEventForm.submit(form => this.robotService.getPlayExecutions(form).pipe(
      tap(playExecutions => {
        if (playExecutions[0]) {
          this.fetchSupportingCaptureEventData(playExecutions[0], form.capture_event_id);
          this.captureEventIdFormGroup.reset();
        }
      }),
      map(playExecutions => ({ playExecutions: playExecutions, captureEventId: form.capture_event_id })))
    );
  }

  protected fetchSupportingData(playExecution: PlayExecution) {
    this.storeService.addStores([playExecution]).subscribe();

    this.robotService.getRobot(playExecution.robotId).subscribe(robot => {
      playExecution.robot = robot;
    });

    this.playService.getPlay(playExecution.playId).subscribe(play => {
      playExecution.play = play;
    });
  }

  protected fetchSupportingScanEventData(playExecution: PlayExecution, scanEventId?: number) {
    this.fetchSupportingData(playExecution);

    if (scanEventId) {
      const params = { id: scanEventId };
      this.insightService.getScanEvents(playExecution.id, params).subscribe(events => playExecution.scanEvents = events);
    }

    this.loading = false;
  }

  protected fetchSupportingCaptureEventData(playExecution: PlayExecution, captureEventId?: number) {
    this.fetchSupportingData(playExecution);

    if (captureEventId) {
      this.robotService.getCaptureEvents(playExecution.id).subscribe(captureEvents => {
        playExecution.captureEvents = captureEvents;

        _.forEach(playExecution.captureEvents, captureEvent => {
          _.forEach(captureEvent.views, view => {
            _.forEach(view.assessmentReviews, assessmentReview => {
              this.reviewService.getInspectAssessment(assessmentReview.assessmentId).subscribe(assessment => assessmentReview.addAssessment(assessment));
            });
          });
        });
      });
    }

    this.loading = false;
  }

  getCaptureEvent(playExecution: PlayExecution, captureEventId: number): CaptureEvent {
    let captureEvent;

    if (playExecution.captureEvents) {
      captureEvent = _.find(playExecution.captureEvents, event => event.id === captureEventId);
    }

    return captureEvent;
  }

  getScanEvent(playExecution: PlayExecution, scanEventId: number): ScanEvent {
    let scanEvent;

    if (playExecution.scanEvents) {
      scanEvent = _.find(playExecution.scanEvents, event => event.id === scanEventId);
    }

    return scanEvent;
  }

  downloadUrgentReport(executionId: number) {
    this.idsGenerating.push(executionId);
    this.robotService.generateUrgentReport(executionId).pipe(
      finalize(() => _.pull(this.idsGenerating, executionId)))
      .subscribe(report => {
        if (report) {
          this.downloadFile(report);
        }
        else {
          this.dialogService.alert(`Urgent report not available for Play Execution ID: ${executionId}`);
        }
      });
  }

  private downloadFile(data) {
    saveAs(new Blob([data], { type: 'application/zip' }), `Capture_Event_Report-${new Date().getTime()}.zip`);
  }

  private createFormGroups(): void {
    this.playExecutionIdFormGroup = new UntypedFormGroup({ id: new UntypedFormControl(null) });
    this.scanEventIdFormGroup = new UntypedFormGroup({ scan_event_id: new UntypedFormControl(null) });
    this.captureEventIdFormGroup = new UntypedFormGroup({ capture_event_id: new UntypedFormControl(null) });
  }

  private addPlayExecutions(playExecutions: PlayExecution[]) {
    if (playExecutions.length !== 0) {
      PlayExecutionSearchComponent.playExecutions.unshift(playExecutions[0]);
      PlayExecutionSearchComponent.playExecutions.length = Math.min(PlayExecutionSearchComponent.playExecutions.length, PlayExecutionSearchComponent.HISTORY_SIZE);
      this.playExecutionDataTable.data = PlayExecutionSearchComponent.playExecutions;
    }
    else {
      this.playExecutionNoResults = true;
    }
  }

  private addScanEvent(playExecution: PlayExecution, scanEventId: number) {
    if (playExecution) {
      PlayExecutionSearchComponent.scanEvents.unshift({ playExecution: playExecution, scanEventId: scanEventId });
      PlayExecutionSearchComponent.scanEvents.length = Math.min(PlayExecutionSearchComponent.scanEvents.length, PlayExecutionSearchComponent.HISTORY_SIZE);
      this.scanEventDataTable.data = PlayExecutionSearchComponent.scanEvents;
    }
    else {
      this.scanEventNoResults = true;
    }
  }

  private addCaptureEvent(playExecution: PlayExecution, captureEventId: number) {
    if (playExecution) {
      PlayExecutionSearchComponent.captureEvents.unshift({ playExecution: playExecution, captureEventId: captureEventId });
      PlayExecutionSearchComponent.captureEvents.length = Math.min(PlayExecutionSearchComponent.captureEvents.length, PlayExecutionSearchComponent.HISTORY_SIZE);
      this.captureEventDataTable.data = PlayExecutionSearchComponent.captureEvents;
    }
    else {
      this.captureEventNoResults = true;
    }
  }

  private createPlayExecutionsTable() {
    const columnDefs: Column[] = [];
    columnDefs.push(new Column('id'));
    columnDefs.push(new Column('store'));
    columnDefs.push(new Column('play'));
    columnDefs.push(new Column('details'));

    this.playExecutionDataTable = new ClientSideDataTable<PlayExecution>(columnDefs);
    this.playExecutionDataTable.data = PlayExecutionSearchComponent.playExecutions;
  }

  private createScanEventsTable() {
    const columnDefs: Column[] = [];
    columnDefs.push(new Column('id'));
    columnDefs.push(new Column('store'));
    columnDefs.push(new Column('play'));
    columnDefs.push(new Column('aisle'));
    columnDefs.push(new Column('details'));

    this.scanEventDataTable = new ClientSideDataTable<{ scanEventId: number, playExecution: PlayExecution }>(columnDefs);
    this.scanEventDataTable.data = PlayExecutionSearchComponent.scanEvents;
  }

  private createCaptureEventsTable() {
    const columnDefs: Column[] = [];
    columnDefs.push(new Column('id'));
    columnDefs.push(new Column('store'));
    columnDefs.push(new Column('play'));
    columnDefs.push(new Column('status'));
    columnDefs.push(new Column('details'));

    this.captureEventDataTable = new ClientSideDataTable<{ captureEventId: number, playExecution: PlayExecution }>(columnDefs);
    this.captureEventDataTable.data = PlayExecutionSearchComponent.captureEvents;
  }

  getMainDateTimeString(timestamp: Date, store: Store): string {
    return this.timezoneService.getMainDateTimeString(timestamp, store);
  }

  onCaptureEventClick(data: any) {
    let currentUrl = "/stores/" + this.captureEventDataTable.data[0].playExecution.storeId + "/inspect/play-executions/" + this.captureEventDataTable.data[0].playExecution.id;
    currentUrl = currentUrl + "?capture-event-id=" + data;
    this.router.navigateByUrl(currentUrl).then( ()=> {});
  }
}
