import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { InsightReviewService } from '@ng-cloud/badger-core/services/insight-review.service';
import { Subscription } from 'rxjs';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { Organization, OrganizationSelectionService, OrganizationService } from '@ng-cloud/badger-core';
import * as _ from 'lodash';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'bt-insight-queue-chips',
    templateUrl: './insight-queue-chips.component.html',
    styleUrls: ['./insight-queue-chips.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class InsightQueueChipsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('reviewsButton') reviewsButton;
  @ViewChild('reviewsMenuTrigger') reviewsMenuTrigger: MatMenuTrigger;

  unassignedPriceReviewCount = 0;
  unassignedSalePriceReviewCount = 0;
  unassignedOOSReviewCount = 0;
  unassignedEOOSReviewCount = 0;
  unassignedViewportReviewCount = 0;
  priceReviewAge: moment.Duration = moment.duration(0);
  salePriceReviewAge: moment.Duration = moment.duration(0);
  oosReviewAge: moment.Duration = moment.duration(0);
  eoosReviewAge: moment.Duration = moment.duration(0);
  viewportReviewAge: moment.Duration = moment.duration(0);
  totalReviewAge: moment.Duration = moment.duration(0);
  isPriceReviewUrgent: boolean;
  isSalePriceReviewUrgent: boolean;
  isOosReviewUrgent: boolean;
  isEoosReviewUrgent: boolean;
  isViewportReviewUrgent: boolean;
  isTotalReviewUrgent: boolean;

  orgList: Organization[] = [];
  selected;
  orgParameters = {};

  readonly thresholdDuration: moment.Duration = moment.duration(30, 'minutes');
  private statSubscription: Subscription;
  private orgSubscription: Subscription;

  @Output() chipSelect = new EventEmitter();
  @Input() componentBreakpoint = 'gt-md';
  @Input() showOrgSwitcher = true;

  private resizeObservable: ResizeObserver;

  constructor(private reviewService: InsightReviewService,
              public media: MediaObserver,
              private organizationService: OrganizationService,
              private orgSelectionService: OrganizationSelectionService) {
  }

  ngAfterViewInit(): void {
    this.createResizeObservable();
  }

  createResizeObservable() {
    this.resizeObservable = new ResizeObserver(entries => {
      if (entries[0].contentRect.width === 0) {
        this.reviewsMenuTrigger?.closeMenu();
      }
    });
    this.resizeObservable.observe(this.reviewsButton._elementRef.nativeElement);
  }

  chipSelected(reviewType) {
    this.chipSelect.emit(reviewType);
  }

  ngOnInit() {
    if (this.showOrgSwitcher) {
      this.orgSubscription = this.orgSelectionService.sharedInsightOrgParams.subscribe(orgParams => {
        this.setupStats(orgParams);
      });
    }
    else {
      this.orgSubscription = this.orgSelectionService.sharedOrgParams.subscribe(orgParams => {
        this.setupStats(orgParams);
      });
    }
    this.organizationService.getOrganizations({ isolate_moderation_queue: true }).subscribe(response => {
      this.updateOrgList(response);
      this.findLastSelectedOrg();
    });
  }

  ngOnDestroy() {
    this.statSubscription && this.statSubscription.unsubscribe();
    this.reviewService.cable().disconnect();
    this.resizeObservable.disconnect();
  }

  setupStats(orgParams) {
    this.orgParameters = orgParams;
    this.reviewService.getStatsByOrg(this.orgParameters).subscribe(response => this.updateReviewStats(response));
    if (this.statSubscription) {
      this.statSubscription.unsubscribe();
    }
    // Ongoing stat changes
    this.statSubscription = this.reviewService.stats(this.orgParameters).subscribe(response => this.updateReviewStats(response));
  }

  updateReviewStats(stats: any) {
    this.unassignedPriceReviewCount = stats['price_unreviewed_unassigned'];
    this.unassignedSalePriceReviewCount = stats['sale_price_unreviewed_unassigned'];
    this.unassignedOOSReviewCount = stats['oos_unreviewed_unassigned'];
    this.unassignedEOOSReviewCount = stats['eoos_unreviewed_unassigned'];
    this.unassignedViewportReviewCount = stats['viewport_unreviewed_unassigned'];

    this.priceReviewAge = moment.duration(stats['price_age_oldest_unreviewed']);
    this.salePriceReviewAge = moment.duration(stats['sale_price_age_oldest_unreviewed']);
    this.oosReviewAge = moment.duration(stats['oos_age_oldest_unreviewed']);
    this.eoosReviewAge = moment.duration(stats['eoos_age_oldest_unreviewed']);
    this.viewportReviewAge = moment.duration(stats['viewport_age_oldest_unreviewed']);
    this.totalReviewAge = moment.duration(stats['total_age_oldest_unreviewed']);

    this.isPriceReviewUrgent = this.priceReviewAge > this.thresholdDuration && this.unassignedPriceReviewCount > 0;
    this.isSalePriceReviewUrgent = this.salePriceReviewAge > this.thresholdDuration && this.unassignedSalePriceReviewCount > 0;
    this.isOosReviewUrgent = this.oosReviewAge > this.thresholdDuration && this.unassignedOOSReviewCount > 0;
    this.isEoosReviewUrgent = this.eoosReviewAge > this.thresholdDuration && this.unassignedEOOSReviewCount > 0;
    this.isViewportReviewUrgent = this.viewportReviewAge > this.thresholdDuration && this.unassignedViewportReviewCount > 0;
    this.isTotalReviewUrgent = this.totalReviewAge > this.thresholdDuration;
  }

  updateOrgList(orgs: Organization[]) {
    this.orgList = orgs;
  }

  changeOrg(org: any) {
    if (org.isUserInput) {
      if (org.source.value !== 'all' && org.source.value !== null) {
        this.orgParameters = { org_guid: org.source.value.guid };
        localStorage.setItem('insight-org-switcher', org.source.value.guid);
      }
      else {
        this.orgParameters = {};
        localStorage.removeItem('insight-org-switcher');
      }
      this.orgSelectionService.updateInsightOrgParams(this.orgParameters);
    }
  }

  findLastSelectedOrg() {
    const selectedOrg = _.find(this.orgList, { guid: localStorage.getItem('insight-org-switcher') });

    if (selectedOrg) {
      this.selected = selectedOrg;
      this.orgParameters = { org_guid: selectedOrg.guid };
      this.orgSelectionService.updateInsightOrgParams(this.orgParameters);
    }
    else {
      this.selected = 'all';
    }
  }
}
