import { AbstractApiModel } from './abstract-api-model';

export class RobotModel extends AbstractApiModel<RobotModel> {
  name: string;
  productId: string;
  features: string[] = [];
  usbDevices: string[] = [];
  batteryThresholds: number[] = [];
  solutions: string[];
  productRevision: string;

  deserialize(json: any): this {
    this.name = json.name;
    this.solutions = json.solutions;
    this.features = json.features;
    this.usbDevices = json.usb_devices;
    this.batteryThresholds = json.battery_thresholds;
    this.productId = json.product_id;
    this.productRevision = json.product_revision;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name,
      product_id: this.productId,
      product_revision: this.productRevision,
      battery_thresholds: this.batteryThresholds,
      solutions: this.solutions,
      features: this.features,
      usb_devices: this.usbDevices
    });
  }
}
