import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Point } from '@ng-cloud/badger-core/models/interfaces/point';
import { Task } from '@ng-cloud/badger-core/models/task';
export class TaskObject extends MapObject {
  defaultOptions: any = {
    objectCaching: false,
    selectable: false,
    evented: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,
    fill: 'red',
    // stroke: 'transparent',
    hasControls: false,
    originX: 'center',
    originY: 'center',
    opacity: 0.25,
    rx: 3,
    ry: 3
  };

  private objectGroup: fabric.Group;
  private circle: fabric.Circle;

  constructor(public task: Task, private points: Point[], config?: any) {
    super(config);
  }

  static getCenterPoint(points: Point[]) {
    let minX = Number.MAX_VALUE, minY = Number.MAX_VALUE;
    let maxX = Number.MIN_VALUE, maxY = Number.MIN_VALUE;

    points.forEach(point => {
      minX = Math.min(minX, point.x);
      maxX = Math.max(maxX, point.x);
      minY = Math.min(minY, point.y);
      maxY = Math.max(maxY, point.y);
    });

    return { x: (maxX + minX) / 2, y: (maxY + minY) / 2 };
  }

  protected build(): fabric.Object {
    const options = this.getOptions();
    const objects: fabric.Object[] = [];
    const centers = TaskObject.getCenterPoint(this.points);
    let minX = Number.MAX_VALUE, minY = Number.MAX_VALUE;

    this.points.forEach(point => {
      minX = Math.min(minX, point.x);
      minY = Math.min(minY, point.y);
    });

    const outerRect: fabric.Rect = new fabric.Rect(options);
    outerRect.top = centers.y;
    outerRect.left = centers.x;
    outerRect.width = 16;
    outerRect.height = 16;
    objects.push(outerRect);

    const innerRect: fabric.Rect = new fabric.Rect(Object.assign(options, { opacity: 1.0 }));

    innerRect.top = centers.y;
    innerRect.left = centers.x;
    innerRect.width = 8;
    innerRect.height = 8;
    innerRect.rx = 2;
    innerRect.ry = 2;
    objects.push(innerRect);
    this.objectGroup = new fabric.Group(objects, options);

    return this.objectGroup;
  }

  protected build2(): fabric.Object {
    const options = this.getOptions();

    let minX = Number.MAX_VALUE, minY = Number.MAX_VALUE;

    this.points.forEach(point => {
      minX = Math.min(minX, point.x);
      minY = Math.min(minY, point.y);
    });

    this.circle = new fabric.Circle(options);
    this.circle.top = minY;
    this.circle.left = minX;
    return this.circle;
  }
}

