<h2 mat-dialog-title>{{user.email ? user.email : 'Create User'}}</h2>

<form [formGroup]="formGroup" (ngSubmit)="user.id ? update() : create()" fxLayout="column">

  <mat-dialog-content>
    <div *ngIf="user" class="user-form-panel">
      <bt-form-field *ngIf="!user.id">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Email</mat-label>
          <input matInput name="email" formControlName="email" placeholder="Email" required/>
          <mat-error *ngIf="formGroup.get('email').touched && formGroup.get('email').hasError('required')">
            Email Required
          </mat-error>
          <mat-error *ngIf="formGroup.get('email').touched && formGroup.get('email').hasError('email')">
            Invalid Email Format
          </mat-error>
        </mat-form-field>
      </bt-form-field>

      <bt-form-field>
        <mat-form-field appearance="outline" fxFill>
          <mat-label>First Name</mat-label>
          <input matInput name="firstName" formControlName="firstName" placeholder="First Name" required/>
          <mat-error *ngIf="formGroup.get('firstName').touched && (formGroup.get('firstName').hasError('required') || formGroup.get('firstName').hasError('whitespace'))">
            First Name Required
          </mat-error>
        </mat-form-field>
      </bt-form-field>

      <bt-form-field>
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Last Name</mat-label>
          <input matInput name="lastName" formControlName="lastName" placeholder="Last Name" required/>
          <mat-error *ngIf="formGroup.get('lastName').touched && (formGroup.get('lastName').hasError('required') || formGroup.get('lastName').hasError('whitespace'))">
            Last Name Required
          </mat-error>
        </mat-form-field>
        <bt-form-errors></bt-form-errors>
      </bt-form-field>

      <bt-form-field *hasAccess="['admin']" errorName="organization">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Organization</mat-label>
          <mat-select formControlName="organizationId" panelClass="selectPopupFull">
            <mat-option *ngFor="let org of orgs" [value]="org.id">
              {{org.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <bt-field-errors></bt-field-errors>
      </bt-form-field>

      <bt-form-field errorName="store" *ngIf="selectedOrg?.orgType !== 'fmcg'">
        <mat-form-field color="accent" appearance="outline">
          <mat-label>Home Store</mat-label>
          <mat-select formControlName="homeStoreId" panelClass="selectPopupFull">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="homeStoreSearchControl"
                placeholderLabel="Search home stores"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option class="mat-accent"
                        *ngFor="let store of filteredHomeStores"
                        [value]="store.id">
              {{ store.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <bt-field-errors></bt-field-errors>
      </bt-form-field>

      <ng-container *ngIf="potentialStoreIds !== 'unrestricted' && this.currentUser?.id !== user?.id">
        <bt-form-field errorName="store">
          <mat-form-field appearance="outline" fxFill>
            <mat-label>Allowed Stores</mat-label>

            <!--
              If singleStoreRestricted is FALSE, show multi-select.
              Otherwise, show single-select.
            -->
            <ng-container *ngIf="!singleStoreRestricted; else singleSelectTemplate">

              <!-- ===================== MULTI-SELECT VERSION ===================== -->
              <mat-select
                formControlName="storeIds"
                panelClass="selectPopupFull"
                multiple
                [disableOptionCentering]="true"
              >

                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="storeSearchControl"
                    placeholderLabel="Search stores"
                  ></ngx-mat-select-search>
                </mat-option>

                <!-- Multi-select options -->
                <mat-option *ngFor="let store of filteredStores" [value]="store.id">
                  {{ store.name }}
                </mat-option>
              </mat-select>

            </ng-container>

            <!-- ===================== SINGLE-SELECT VERSION ===================== -->
            <ng-template #singleSelectTemplate>
              <mat-select
                formControlName="singleStoreSelection"
                panelClass="selectPopupFull"
                [disableOptionCentering]="true"
                (selectionChange)="onSingleStoreSelection($event)">

                <mat-option>
                  <ngx-mat-select-search [formControl]="storeSearchControl" placeholderLabel="Search stores">
                  </ngx-mat-select-search>
                </mat-option>

                <!-- Single-select options -->
                <mat-option *ngFor="let store of filteredStores" [value]="store.id">
                  {{ store.name }}
                </mat-option>
              </mat-select>
            </ng-template>

          </mat-form-field>
          <bt-field-errors></bt-field-errors>
        </bt-form-field>
      </ng-container>


      <bt-form-field *hasAccess="['organization_admin', 'Organization Executive', 'Regional Manager', 'Store Manager', 'IT']">
        <mat-form-field appearance="outline" *ngIf="roleOptions.length > 0" fxFill>
          <mat-label>Roles</mat-label>
          <mat-select formControlName="roles" panelClass="selectPopupFull" multiple>
            <mat-option *ngFor="let role of roleOptions" [value]="role">{{role | titlecase}}</mat-option>
          </mat-select>
        </mat-form-field>
        <bt-field-errors></bt-field-errors>
      </bt-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button [matDialogClose]="">Cancel</button>
    <button mat-flat-button color="primary" type="submit"
            [disabled]="formGroup.invalid">{{user.id ? "Save" : 'Create'}}</button>
  </mat-dialog-actions>
</form>
