import { AbstractZone } from './abstract-zone';
import * as _ from 'lodash';

export class ScanZone extends AbstractZone<ScanZone> {
  name: string;
  zoneType: string;
  storeId: number;
  speed: number;
  timeout: number;
  settings: any;


  deserialize(json: any): this {
    this.name = json.name;
    this.zoneType = json.zone_type;
    this.storeId = json.store_id;
    this.speed = json.speed;
    this.timeout = json.timeout;
    this.settings = json.settings;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name,
      zone_type: this.zoneType,
      store_id: this.storeId,
      speed: this.speed,
      timeout: this.timeout,
      settings: this.settings
    });
  }

  sortArray(): string[] {
    return _.chain(this.name.toLowerCase().trim())
      .split(/\W+/)
      .map(section => section.match(/\d+|\D+/g))
      .flatten()
      .map(section => /\d+/.test(section) ? section.padStart(5, '0') : section)
      .value();
  }

  static zoneSort = (a: string[], b: string[]) => {
    for (let i = 0; i < a.length && i < b.length; i++) {
      const compareValue = a[i].localeCompare(b[i]);
      if (compareValue !== 0) {
        return compareValue;
      }
    }
    return a.toString().localeCompare(b.toString());
  };
}
