import { Component, ElementRef, HostListener, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppForm, DialogService } from '@ng-cloud/badger-core';
import { of } from 'rxjs';
import { DashboardConfigItem, EditDashboardData } from '../../../shared/analytic-widgets/types';

@Component({
    selector: 'insight-edit-dashboard-form',
    templateUrl: './edit-dashboard-form.component.html',
    styleUrls: ['./edit-dashboard-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class EditDashboardFormComponent {
    @ViewChild('nameInput') nameInput: ElementRef;
    formGroup: UntypedFormGroup;
    form: AppForm<EditDashboardData>;

    title = 'Edit Dashboard';
    allItems = [];
    readonly dashboards;
    selectedItems = [];
    dashboardName;
    dashboardTitle;
    editMode = true;

    configData: EditDashboardData;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { title: string, name: string, items: string[], dashboards: [{ name: string, content: DashboardConfigItem[] }], selected: string[] },
        public dialogRef: MatDialogRef<EditDashboardFormComponent>,
        private dialogService: DialogService,
    ) {
        this.dashboardName = data.name;
        this.dashboardTitle = data.title;
        if (!data.title) {
            this.title = "Create dashboard";
            this.editMode = false;
        }
        this.selectedItems = [...data.selected].sort();
        this.allItems = [...data.items].filter(el => !this.selectedItems.includes(el)).sort(); // remove already selected
        this.dashboards = data.dashboards;
        this.formGroup = this.createFormGroup();
        this.form = new AppForm(this.formGroup, this.configData, new EditDashboardData(), f => this.onSuccess(f));
    }

    createFormGroup(): UntypedFormGroup {
        return new UntypedFormGroup({
            title: new UntypedFormControl(this.dashboardTitle, [this.nameValidator()]),
            name: new UntypedFormControl(this.dashboardName, [this.nameValidator()]),
        });
    }

    nameValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const fail = !control.value.replace(/\s/g, '').length;
            return fail ? { Invalid: { value: control.value, msg: 'Empty or invalid string' } } : null;
        };
    }

    create(): void {
        const title = this.formGroup.controls.title.value.trim().replace(/\s+/g, " ").toUpperCase(); // remove extra spaces between words
        const name = this.formGroup.controls.name.value.trim().replace(/\s+/g, " "); // remove extra spaces between words
        let alert = false;

        const titles = this.dashboards.map(e => e.name);

        // check unique Abbreviation and Title:
        if ((this.dashboardTitle != title) && (titles.find(t => t == title))) {
            alert = true;
            this.dialogService.alert('This Abbreviation already exists, enter another.');
        }
        if (this.dashboardName != name) {
            const i = this.dashboards.findIndex(e => e.content[0].name == name);
            if (i != -1) {
                alert = true;
                this.dialogService.alert('This Title already exists, enter another.');
            }
        }

        if (!alert) {
            this.form.submit(() => of({ name, title, items: this.selectedItems }));
        }
    }

    onSelectItem(item: string) {
        this.selectedItems.push(item);
        this.allItems.splice(this.allItems.indexOf(item), 1);

        this.selectedItems.sort();
    }

    // upper case input text and maintain cursor position:
    inputUpperCase(e) {
        const el = e.target;
        const start = el.selectionStart;
        const end = el.selectionEnd;
        el.value = el.value.toUpperCase();
        el.setSelectionRange(start, end);
    }

    onDeleteItem(item: string) {
        this.allItems.push(item);
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        this.allItems.sort();
    }

    onSuccess(f: EditDashboardData) {
        this.dialogRef.close(f);
    }

    @HostListener('window:keyup', ['$event'])
    onDialogClick(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.stopPropagation();
        }
    }

}
