import { Component } from '@angular/core';

@Component({
    selector: 'bt-progress-overlay',
    templateUrl: './progress-overlay.component.html',
    styleUrls: ['./progress-overlay.component.scss'],
    standalone: false
})
export class ProgressOverlayComponent {
  progressValue = 0;

  constructor() {
  }

  public setProgressValue(value: number) {
    this.progressValue = value;
  }
}
